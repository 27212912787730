import { Add, Close, CopyAll, Delete, Edit, ExpandLess, ExpandMore, Groups, Save } from "@mui/icons-material";
import { Alert, Box, Button, Card, Checkbox, Chip, CircularProgress, Collapse, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, List, ListItem, MenuItem, Modal, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import ptBR from 'dayjs/locale/pt-br';
import { CopyOutlined } from "@ant-design/icons";
import CustomerGroupSelect from "../CustomerGroupSelect";
import { formatDatetimeLocale } from "../../util";

function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

function ScheduledMessage(props) {
  const { message } = props;

  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [messageContent, setMessageContent] = useState(message.message);

  const handleMessageChange = (changeEvt) => {
    setMessageContent(changeEvt.target.value);
  };

  return (
    <Grid container alignItems={"center"}>
      <Grid item sm="auto">
        <IconButton onClick={() => setIsMessageOpen(!isMessageOpen)}>
          { isMessageOpen ? (<ExpandLess/>) : (<ExpandMore/>) }
        </IconButton>
      </Grid>
      <Grid item sm>
        <Typography>{message.title}</Typography>
      </Grid>
      <Grid item sm="auto">
        <Typography>
          {props.message.sendAt}
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <Collapse in={isMessageOpen}>
          <ScheduledMessageEditor message={props.message} />
        </Collapse>
        <Box sx={{ height: "20px" }}></Box>
      </Grid>
    </Grid>
  )
}

function ScheduledMessageEditor(props) {
  const getTargetGroups = () => {
    return (props.message.targetGroups?.length > 0) ? props.message.targetGroups[0] : null;
  };

  const [isTitleEmpty, setIsTitleEmpty] = useState(props.message.title?.length == 0);
  const [selectingGroupSelection, setSelectingGroupSelection] = useState(getTargetGroups());
  const [isEditingGroup, setIsEditingGroup] = useState(false);

  const [shouldShowSnack, setShouldShowSnack] = useState(false);
  const [snackText, setSnackText] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("info");

  const [message, setMessage] = useState({
    ...props.message,
  });

  const handleCloseSnack = () => {
    setShouldShowSnack(false);
  };

  const handleChange = (newMessage) => {
    props.onChange(newMessage);
    setMessage(newMessage);
    setSelectingGroupSelection(newMessage.targetGroups?.length > 0 ? newMessage.targetGroups[0] : null);
    // console.debug(`${JSON.stringify(newMessage)}`);
    // console.debug(selectingGroupSelection);
  };

  return (
    <FormControl fullWidth>
      <Snackbar
        open={shouldShowSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          severity={snackSeverity}
          onClose={handleCloseSnack}
        >
          {snackText}
        </Alert>
      </Snackbar>
      <Modal open={isEditingGroup}>
        <Card sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: "900px",
          boxShadow: 24,
          p: "4px 4px 4px 4px",
        }}>
          <CustomerGroupSelect selected={selectingGroupSelection} onGroupSelection={(group) => {
            setSelectingGroupSelection(group);
          }}/>
          <Grid container justifyContent="right" columnSpacing={2} sx={{mt: 2}}>
            <Grid item>
              <Button variant="text" onClick={() => {
                setSelectingGroupSelection(getTargetGroups());
                setIsEditingGroup(false)
              }}>Cancelar</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => {
                // console.debug(`Saving groups`);
                handleChange({
                  ...message,
                  targetGroups: selectingGroupSelection ? [selectingGroupSelection] : undefined,
                });
                setIsEditingGroup(false);
              }}>Salvar</Button>
            </Grid>
          </Grid>
        </Card>
      </Modal>
      <Grid
        container
        sx={{
          pl: "4px",
          pr: "4px",
          pb: "4px",
        }}
        rowSpacing={2}
        columnSpacing={1}
      >
        <Grid item sm={12}>
          <TextField
            value={message.title}
            error={isTitleEmpty}
            helperText={isTitleEmpty ? "Título não pode estar vazio" : ""}
            fullWidth
            label="Título da mensagem"
            onChange={(event) => {
              setIsTitleEmpty(event.target.value.length == 0);
              handleChange({
                ...message,
                title: event.target.value,
              });
            }}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            multiline
            fullWidth
            minRows={6}
            maxRows={10}
            value={message.message}
            placeholder="Digite sua mensagem"
            onChange={(event) => {
              handleChange({
                ...message,
                message: event.target.value,
              });
            }}
          />
        </Grid>

        <Grid item sm={12} align="left">
          <FormControlLabel
            label="Nome"
            control={
              <IconButton
                onClick={() => {
                  setShouldShowSnack(true);
                  setSnackSeverity("info");
                  setSnackText("Variável copiada! Cole-a onde quiser na mensagem.");
                  navigator.clipboard.writeText("{{nome}}");
                }}
              >
                <CopyAll/>
              </IconButton>
            }
          />
        </Grid>

        <Grid item sm={4}>
          <FormControlLabel
            control={<Checkbox
              checked={message.enabled}
              onChange={() => {
                handleChange({
                  ...message,
                  enabled: !message.enabled,
                });
              }}
            />}
            label="Ativo"
          />
        </Grid>
        <Grid item sm={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale={ptBR}>
            <DateTimePicker
              label="Data de envio"
              views={['year', 'month', 'day', 'hours']}
              value={dayjs(message.sendAt)}
              onChange={(date) => {
                handleChange({
                  ...message,
                  sendAt: new Date(date),
                });
              }}
              renderInput={(props) => {
                return <TextField {...props} />;
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={4}>
          <TextField
            select
            fullWidth
            value={message.recurrency}
            onChange={(event) => {
              handleChange({
                ...message,
                recurrency: event.target.value,
              });
            }}
            label="Recorrência"
            helperText={
              message.recurrency === "sameDayNextMonth" ? "Sempre no mesmo dia de cada mês" : undefined
            }
          >
            <MenuItem value="none">Apenas uma vez</MenuItem>
            <MenuItem value="daily">Diária</MenuItem>
            <MenuItem value="weekly">Semanal</MenuItem>
            <MenuItem value="sameDayNextMonth">Mensal</MenuItem>
            <MenuItem value="after30days">A cada 30 dias</MenuItem>
          </TextField>
        </Grid>

        <Grid item sm={4}>
          <FormControlLabel
            control={<Checkbox
              checked={message.sendToAllContacts}
              onChange={() => {
                handleChange({
                  ...message,
                  sendToAllContacts: !message.sendToAllContacts,
                });
              }}
            />}
            label="Enviar para todos os contatos"
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            label="Grupo"
            disabled={getTargetGroups() == undefined}
            onClick={(e) => {
              e.preventDefault();
            }}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment>
                  <Button onClick={() => setIsEditingGroup(true)}><Groups/></Button>
                </InputAdornment>
              ),
            }}
            value={selectingGroupSelection ? selectingGroupSelection.name : "Nenhum"}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
}

function ScheduledMessageEditMode(props) {
  const [edited, setEdited] = useState(false);
  const scheduledMessage = useRef({ ...props.scheduledMessage });
  // console.debug(`Hello ${JSON.stringify(scheduledMessage.current)}`);

  return (
    <Grid
      container
      justifyContent={"right"}
      sx={{
        mt: "16px",
        mb: "16px",
      }}>
      <Grid item sx={12}>
        <ScheduledMessageEditor
          message={scheduledMessage.current}
          onChange={(msg) => {
            scheduledMessage.current = msg;
            setEdited(true);
          }}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          disabled={!edited}
          onClick={() => {
            props.onScheduleMessageUpdate(scheduledMessage.current);
          }}
        >Salvar</Button>
      </Grid>
    </Grid>
  );
}

export default function MessageScheduler() {
  const [scheduledMessages, setScheduledMessages] = useState([]);
  const [isSchedulingMessage, setIsSchedulingMessage] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);

  const scheduledMessageDefault = {
    targetGroups: [],
    sendToAllContacts: false,
    title: "Mensagem agendada",
    message: "",
    sendAt: new Date(),
    recurrency: "none",
    enabled: false,
  };
  const editScheduledMessage = useRef({
    ...scheduledMessageDefault,
  });
  const [isCreatingMessage, setIsCreatingMessage] = useState(false);

  const [selectedMessages, setSelectedMessages] = useState([]);
  const [messageState, setMessageState] = useState([]);

  const [shouldShowSnack, setShouldShowSnack] = useState(false);
  const [snackText, setSnackText] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("info");

  const handleCloseSnack = () => {
    setShouldShowSnack(false);
  };

  const updateScheduledMessageList = (isMountedObject) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL_API}/scheduled-messages`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
    };
    setIsLoadingList(true)
    axios.request(options)
      .then((response) => {
        if (isMountedObject.isMounted) {
          setScheduledMessages(response.data);
          setMessageState(response.data.map((msg) => {
            return {
              selected: false,
              expanded: false,
              edited: false,
            };
          }));
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoadingList(false);
      });
  };

  useEffect(() => {
    let isMountedObject = {
      isMounted: true,
    };

    updateScheduledMessageList(isMountedObject);

    return () => { isMountedObject.isMounted = false };
  }, []);


  const handleChangeEditScheduledMessage = (newScheduledMessage) => {
    editScheduledMessage.current = newScheduledMessage;
  };

  const createScheduledMessage = (messageToCreate) => {
    setIsLoadingList(true);
    setIsCreatingMessage(true);

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/scheduled-messages`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
      data: messageToCreate,
    };
    axios.request(options)
      .then((response) => {
        setIsCreatingMessage(false);
        setIsSchedulingMessage(false);
        updateScheduledMessageList({ isMounted: true });
        editScheduledMessage.current = { ...scheduledMessageDefault };
      })
      .catch((error) => {
        setShouldShowSnack(true);
        setSnackSeverity("error");
        setSnackText("Não foi possível agendar a mensagem");
        setIsLoadingList(false);
        setIsCreatingMessage(false);
        console.error(error);
      });
  };

  const updateScheduledMessage = (updatedMessage) => {
    setIsLoadingList(true);

    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL_API}/scheduled-messages/${updatedMessage._id}`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
      data: updatedMessage,
    };
    axios.request(options)
      .then((_response) => {
        updateScheduledMessageList({ isMounted: true });
      })
      .catch((error) => {
        setShouldShowSnack(true);
        setSnackSeverity("error");
        setSnackText("Não foi possível atualizar a mensagem");
        setIsLoadingList(false);
        console.error(error);
      });
  };

  const deleteScheduledMessage = (messageToDelete) => {
    setIsLoadingList(true);

    const options = {
      method: "DELETE",
      url: `${process.env.REACT_APP_BASE_URL_API}/scheduled-messages/${messageToDelete._id}`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
    };
    axios.request(options)
      .then((_response) => {
        updateScheduledMessageList({ isMounted: true });
      })
      .catch((error) => {
        setShouldShowSnack(true);
        setSnackSeverity("error");
        setSnackText("Não foi possível deletar a mensagem");
        setIsLoadingList(false);
        console.error(error);
      });
  };


  return <>
    <Snackbar
      open={shouldShowSnack}
      autoHideDuration={6000}
      onClose={handleCloseSnack}
    >
      <Alert
        severity={snackSeverity}
        onClose={handleCloseSnack}
      >
        {snackText}
      </Alert>
    </Snackbar>
    <Grid container>
      <Grid item sm={1}></Grid>
      <Grid item>
        <Button onClick={() => setIsSchedulingMessage(true)}>
          <><Add />Adicionar</>
        </Button>
      </Grid>
    </Grid>
    <Modal
      open={isSchedulingMessage}
    >
      <Card sx={{
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        width: "900px",
        boxShadow: 24,
        p: "4px 4px 28px 28px",
      }}>
        <Grid container>
          <Grid item sm={12} align="right">
            <IconButton onClick={() => setIsSchedulingMessage(false)}><Close/></IconButton>
          </Grid>
        </Grid>
        <Box pr="28px">
          <ScheduledMessageEditor
            message={editScheduledMessage.current}
            onChange={handleChangeEditScheduledMessage}
          />
        </Box>
        <Box
          mt="16px"
          mr="28px"
        >
          <Grid container columnSpacing={2}>
            <Grid item sm></Grid>
            <Grid item sm="auto">
              <Button
                variant="text"
                onClick={() => setIsSchedulingMessage(false)}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item sm="auto">
              { isCreatingMessage ? (
                <CircularProgress/>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => createScheduledMessage(editScheduledMessage.current)}
                >
                  Criar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Modal>
    {isLoadingList && (
      <Grid container justifyContent={"center"}>
        <Grid item>
          <CircularProgress
            size={68}
            sx={{
              margin: "100px 100px 100px 100px",
            }}
          />
        </Grid>
      </Grid>
    )}
    {!isLoadingList && (
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Título</TableCell>
              <TableCell align="center">Próximo envio</TableCell>
              <TableCell align="center"></TableCell>
            </TableHead>
            <TableBody>
              {scheduledMessages.map((message, i) => {
                let selected = false;
                if (selectedMessages.findIndex((el) => el._id === message._id) >= 0) {
                  selected = true;
                }
                return (
                  <>
                    <TableRow key={i} sx={{width: "100%"}}>
                      <TableCell align="left">
                        <IconButton
                          onClick={() => {
                            const newArray = [...messageState];
                            newArray[i] = {
                              ...newArray[i],
                              expanded: !newArray[i].expanded,
                            };
                            setMessageState(newArray);
                          }}
                        >
                          {messageState[i].expanded ? (
                            <ExpandLess/>
                          ) : (
                            <ExpandMore/>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        {message.title}
                      </TableCell>
                      <TableCell align="center">
                        {formatDatetimeLocale(message.sendAt)}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => {
                          deleteScheduledMessage(scheduledMessages[i]);
                        }}><Delete/></IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                        <Collapse in={messageState[i].expanded}>
                          <Card sx={{mt: "16px", mb: "16px", pl: "16px", pr: "16px"}}>
                            <ScheduledMessageEditMode
                              scheduledMessage={scheduledMessages[i]}
                              onScheduleMessageUpdate={(msg) => {
                                updateScheduledMessage(msg);
                              }}
                            />
                          </Card>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    )}
  </>;
}