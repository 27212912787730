import { Grid, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { codes } from "../../constants/countrycodes";

export function PhoneNumberInput(props) {
  const PHONE_NUMBER_GENERAL_REGEX = /^[0-9]*$/;
  const PHONE_DDI_REGEX = /^\+[0-9]+/;

  const [codeCountry, setCodeCountry] = useState("+55🇧🇷");

  const ddiRegResult = PHONE_DDI_REGEX.exec(codeCountry)
  let ddi = "+55";
  if (ddiRegResult && ddiRegResult.length > 0) {
    ddi = ddiRegResult[0];
  }

  const textFieldProps = {
    ...props,
    value: props.value.substring(ddi.length),
    onChange: (event) => {
      // console.debug(`Testing event: ${event.target.value}`);
      if (PHONE_NUMBER_GENERAL_REGEX.test(event.target.value)) {
        const ddi = PHONE_DDI_REGEX.exec(codeCountry)
        props.onChange(ddi + event.target.value);
      }
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCodeCountry(value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        border: "none",
      },
    },
  };

  return (
    <Grid container width="100%" alignItems="center">
      <Grid item xs="auto">
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={codeCountry}
          onChange={handleChange}
          style={{ marginRight: 10 }}
          MenuProps={MenuProps}
        >
          {codes.map((option) => (
            <MenuItem
              key={option.name}
              value={option.dial_code + option.flag}
            >
              {option.dial_code} {option.flag}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs>
        <TextField {...textFieldProps} fullWidth/>
      </Grid>
    </Grid>
  );
}