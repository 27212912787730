import React, { useState } from "react";

import TextField from "@mui/material/TextField";

import SendIcon from "@mui/icons-material/Send";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import IconButton from "@mui/material/IconButton";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import LoadingButton from "@mui/lab/LoadingButton";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import Contacs from "./contacts";

function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

const encodeMessage = (msg, media, filename) => {
  const filenameTag = filename === "" ? "" : `%Filename%${filename}%/Filename%`;

  return media === ""
    ? msg
    : `${msg}%SendFile%${filenameTag}%Url%${media
        .split(/\s/)
        .join("")}%/Url%%/SendFile%`;
};

export default function ChipsArray() {
  const [loading, setLoading] = React.useState(false);

  const [allNumbersArray, setAllNumbersArray] = React.useState([]);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [chosenEmojiState, setChosenEmojiState] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgAlert, setMsgAlert] = useState("");

  const [media, setMedia] = useState("");
  const [filename, setFileName] = useState("");

  const [alertErroState, setAlertErroState] = useState(false);

  const [alertSucessState, setAlertSucessState] = useState(false);

  const setNumbers = (array) => {
    console.log(array);
    setAllNumbersArray(array);
  };

  const send = () => {
    setLoading(true);
    if (!isURL(media)) {
      // setLoading(false);
    } else {
      setMedia("");
    }
    if (msg === "") {
      setMsgAlert("O campo mensagem não pode ser vazio.");
      setAlertErroState(true);
      setLoading(false);
      return;
    } else {
      setAlertErroState(false);
    }

    if (allNumbersArray.length < 1) {
      setMsgAlert("Por favor adicione os numeros para envio da mensagem.");
      setAlertErroState(true);
      setLoading(false);
      return;
    } else {
      setAlertErroState(false);
    }

    allNumbersArray.map((item, key) => {
      const isGroup = item.number.length > 17;
      const isOldGroup = item.number.length > 21;
      let phone = item.number;
      if (isGroup) {
        if (!isOldGroup) {
          phone = item.number.slice(-18);
        }
        if (isOldGroup) {
          phone = item.number.slice(-22);
        }
      }
      let options = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL_API}/messages/sendMessage${
          isGroup ? "Group" : ""
        }`,
        headers: { "Content-Type": "application/json" },
        data: {
          clientApi: getParam("api"),
          clientSecret: getParam("secret"),
          phone,
          message: encodeMessage(msg, media, filename),
        },
      };
      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
          setMsgAlert("Mensagem adicionada a fila com sucesso.");
          setAlertSucessState(true);
          setLoading(false);
        })
        .catch(function (error) {
          // console.debug(`error: ${JSON.stringify(error)}`);
          if (error.response && error.response.status === 403) {
            setMsgAlert(
              `Não houve permissão para enviar a mensagem: ${error.response.data.message}`
            );
          } else {
            setMsgAlert(
              "Erro ao adicionar mensagem na fila, vefique se sua instância está logada."
            );
          }
          setAlertErroState(true);
          setLoading(false);
        })
        .catch((erro) => {
          console.log(erro);
          setLoading(false);
        });
      return null;
    });
    setMsg("");
    setMedia("");
    return null;
  };

  const onEmojiClick = (event, emojiObject) => {
    let tmp = msg + emojiObject.emoji + " ";
    setMsg(tmp);
    setChosenEmoji(emojiObject);
    setChosenEmojiState(false);
  };

  function getFilename(url) {
    if (url) {
      const filename = url.substring(url.lastIndexOf("/") + 1);

      setFileName(filename);
    }
    return "";
  }
  function isURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res != null) {
      getFilename(string);
      switch (extension(string)) {
        case "pdf":
          setMedia(string);
          break;

        case "png":
          setMedia(string);
          break;

        case "jpg":
          setMedia(string);
          break;

        case "jpeg":
          setMedia(string);
          break;

        case "gif":
          setMedia(string);
          break;

        case "xlxs":
          setMedia(string);
          break;

        case "docx":
          setMedia(string);
          break;

        default:
          setMedia("");
          break;
        //return false;
      }
    }
  }

  function extension(fname) {
    var pos = fname.lastIndexOf(".");
    var strlen = fname.length;
    if (pos !== -1 && strlen !== pos + 1) {
      var ext = fname.split(".");
      var len = ext.length;
      var extension = ext[len - 1].toLowerCase();
    } else {
      extension = "No extension found";
    }
    return extension;
  }

  const handlerMedia = (link) => {
    setMedia(link);
  };

  return (
    <>
      {loading && <LinearProgress />}

      <div style={{ marginTop: 10 }}>
        {alertErroState && (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertErroState(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {msgAlert}
          </Alert>
        )}
        {alertSucessState && (
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertSucessState(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
            severity="success"
          >
            {msgAlert}
          </Alert>
        )}
      </div>
      <Contacs numbers={setNumbers} />

      <div style={{ marginTop: 20 }}>
        <Typography style={{ color: "#707070" }} display="block">
          Insira o link do arquivo:
        </Typography>
        <TextField
          placeholder="https://seulink.com.br/logo.png"
          style={{ width: "100%" }}
          value={media}
          onChange={(value) => handlerMedia(value.target.value)}
        />
      </div>
      <div style={{ marginTop: 30, flex: 1 }}>
        <div>
          <TextField
            id="outlined-multiline-flexible"
            label="Digite sua mensagem..."
            multiline
            style={{ width: "100%" }}
            rows={7}
            onClick={() => setChosenEmojiState(false)}
            value={msg}
            onChange={(ev) => setMsg(ev.target.value)}
          />
          {!chosenEmojiState ? (
            <>
              <Typography
                style={{ color: "#707070", marginTop: 20 }}
                display="block"
              >
                Emoticons
              </Typography>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setChosenEmojiState(!chosenEmojiState)}
              >
                <EmojiEmotionsIcon
                  style={{
                    cursor: "default",
                    color: "#ffca28",
                    background: "#eee",
                    borderRadius: 100,
                  }}
                />
              </IconButton>
            </>
          ) : (
            <div />
          )}
          {chosenEmojiState ? (
            <Picker
              onEmojiClick={onEmojiClick}
              disableAutoFocus={true}
              pickerStyle={{ width: "100%" }}
              skinTone={SKIN_TONE_MEDIUM_DARK}
              groupNames={{ smileys_people: "PEOPLE" }}
              native
            />
          ) : (
            <div />
          )}
        </div>
        <div style={{ marginTop: 20, width: "100%" }} />
        <LoadingButton
          onClick={send}
          endIcon={<SendIcon />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          Adicionar a Fila
        </LoadingButton>
      </div>
    </>
  );
}
