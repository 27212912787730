import React, { useState, useEffect } from "react";
import { Paper, Alert, Stack, TextField, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { stylePaper } from "./styles";

function ChangeDisconnectionNotificationNumber() {
  return <QueryParams />;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function QueryParams() {
  const [disconnectionNotificationNumber, setDisconnectionNotificationNumber] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  let query = useQuery();

  const handleChange = (event) => {
    setLoading(true);
    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/changeDisconnectionNotificationNumber`,
      headers: { "Content-Type": "application/json" },
      data: {
        clientApi: query.get("api"),
        clientSecret: query.get("secret"),
        disconnectionNotificationNumber: disconnectionNotificationNumber,
      },
    };
    axios
      .request(options)
      .then(function (response) {})
      .catch(function (error) {
        console.error(error);
      });
    setTimeout(() => {
      setLoading(false);
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    }, 2000);
  };

  useEffect(() => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/informations`,
      headers: { "Content-Type": "application/json" },
      data: { clientApi: query.get("api"), clientSecret: query.get("secret") },
    };

    axios
      .request(options)
      .then(function (response) {
        if (
          response.data.info &&
          response.data.info.me &&
          response.data.info.me.user
        ) {
          setDisconnectionNotificationNumber(response.data.info.me.user);
        }
        if (response.data.disconnectionNotificationNumber) {
          setDisconnectionNotificationNumber(
            response.data.disconnectionNotificationNumber
          );
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <Paper elevation={6} style={stylePaper}>
      {showMessage && (
        <Alert severity="success" sx={{ width: "100%" }}>
          Número que receberá a notificação alterada com sucesso!
        </Alert>
      )}
      <Stack
        spacing={4}
        justifyContent="center"
        alignItems="center"
        direction="row"
        style={{ paddingTop: 10, PaddingEnd: 5 }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Número WhatsApp"
            variant="outlined"
            placeholder="Ex: 55999999999"
            value={disconnectionNotificationNumber}
            onChange={(e) => setDisconnectionNotificationNumber(e.target.value)}
          />
          <LoadingButton
            loading={loading}
            variant="outlined"
            onClick={handleChange}
            style={{ marginLeft: 10 }}
          >
            Alterar
          </LoadingButton>
        </Box>
        <Alert severity="info" color="info">
          <b>NOTIFICAÇÃO DE DESCONEXÃO</b>
          <br />
          <br />* Quando sua instância estiver desconectada, vamos te avisar
          atráves do número cadastrado.
        </Alert>
      </Stack>
    </Paper>
  );
}

export default ChangeDisconnectionNotificationNumber;
