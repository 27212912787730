import React, { useEffect, useState } from "react";
import AlertTitle from "@mui/material/AlertTitle";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import axios from "axios";

import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: "rgb(229, 246, 253)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}
const stylePaper = {
  margin: 10,
  padding: 10,
};

export default function DescriptionAlerts() {
  const [stateApi, setStateApi] = useState("Inativa");
  const [server, setServer] = useState("");
  const [number, setNumber] = useState("");
  const [wpVersion, setWpVersion] = useState("");
  const [timeInterval, setTimeInterval] = useState(0);

  useEffect(() => {
    getInfo();
  }, []);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 5000);

  const getInfo = () => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/informations`,
      headers: { "Content-Type": "application/json" },
      data: {
        clientApi: getParam("api"),
        clientSecret: getParam("secret"),
      },
    };

    axios
      .request(options)
      .then(function (response) {
        let res = response.data;
        let info = res.info;
        if (res.isSuspended != true) {
          setStateApi("Ativa");
        } else {
          setStateApi("Inativa");
        }
        setServer(res.numberServer);
        setNumber(info.me.user);
        //setWpVersion(info.phone.wa_version);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <Paper sx={{ margin: 2 }} elevation={2}>
      <Item severity="info">
        <AlertTitle>
          <strong>Informações da sua API</strong>
        </AlertTitle>
        <Grid container alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div style={{ alignItems: "center" }}>
              <div style={{ textAlign: "initial", fontSize: 12 }}>
                <div>
                  <strong>Usuário: </strong>
                  {getParam("api")}
                </div>
                <div>
                  <strong>Senha:</strong> {getParam("secret")}
                </div>
                <div>
                  <strong>Servidor:</strong> {server}
                </div>
                <div>
                  <strong>Status:</strong>{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: stateApi === "Ativa" ? "blue" : "red",
                    }}
                  >
                    {stateApi}
                  </span>
                </div>
                <div>
                  <strong>Número: </strong>
                  {number}
                </div>
                {/*<div>
                  <strong>Versão do WhatsApp:</strong> {wpVersion}
                </div>*/}
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div style={{ textAlign: "initial" }}>
              <p>
                Servidor da API: <strong>Servidor {server}.</strong>
              </p>
              <p>
                Número escaneado: <strong>{number}.</strong>
              </p>
              <p>
                Versão do WhatsAPp: <strong>{wpVersion}</strong>
              </p>
            </div>
          </Grid> */}
        </Grid>
      </Item>
    </Paper>
  );
}
