import React, { useState, useEffect } from "react";
import { Paper, Alert, Stack, TextField, Box, Grid, Button, InputAdornment, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { stylePaper } from "./styles";
import { Close, Delete } from "@mui/icons-material";
import { PhoneNumberInput } from "../PhoneNumberInput";

function ChangeDailyReportNumber(props) {
  return <QueryParams {...props} />;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function QueryParams(props) {
  const { informations, onCancel, onSuccess } = props;

  const [dailyReportNumber, setDailyReportNumber] =
    useState(
      informations.dailyReportNumber ?? ""
    );
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  let query = useQuery();

  const handleChange = (event) => {
    let numberToSet = null;
    if (dailyReportNumber.length > 0) {
      numberToSet = dailyReportNumber;
    }
    setLoading(true);
    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/changeDailyReportNotificationNumber`,
      headers: { "Content-Type": "application/json" },
      data: {
        clientApi: query.get("api"),
        clientSecret: query.get("secret"),
        dailyReportNumber: numberToSet,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
    setTimeout(() => {
      setLoading(false);
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    }, 2000);
  };

  return (
    <Grid container rowSpacing={2} justifyContent={"center"}>
      <Grid item xs={12}>
        <Alert severity="info" color="info">
          <b>RELATÓRIO DIÁRIO DA SUA API DE NOTIFICAÇÕES</b>
          <br />
          <br />Todo dia as 17:00, um relatório sobre sua API será enviado ao número definido abaixo.
        </Alert>
      </Grid>
      <Grid item>
        <Box
          sx={{
          display: "flex",
          flexWrap: "wrap",
          }}
        >
          <PhoneNumberInput
            value={dailyReportNumber}
            onChange={(val) => setDailyReportNumber(val)}
          />
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleChange}
            style={{ marginLeft: 10 }}
          >
          Alterar
          </LoadingButton>
          <Button
            style={{ marginLeft: 10 }}
            variant="outlined"
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
            }}>
            Cancelar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ChangeDailyReportNumber;
