import React, { useState, useEffect } from "react";
import { Paper, Box, LinearProgress, Typography } from "@mui/material";
import WorkRiskFree from "../../assets/WorkRiskFree.svg";

function QrRead() {
  const [progress, setProgress] = useState(10);

  const stylePaper = {
    margin: 10,
    padding: 10,
  };

  useEffect(() => {
    setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 200);

  }, []);

  return (
    <Paper elevation={6} style={stylePaper}>
      {progress !== 100 ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel value={progress} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
            m: 2,
            bgcolor: "background.paper",
          }}
        >
          <img style={{ width: "50%" }} src={WorkRiskFree} />
          <h4>QRCode Autenticado com sucesso!</h4>
        </Box>
      )}
    </Paper>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default QrRead;
