import { useEffect, useState, useRef } from "react";
import { useIsVisible } from "react-is-visible";
import delay from "delay";
import { useVisibilityChange } from "use-visibility-change";
import { Alert, AlertTitle, Box, CircularProgress, Paper } from "@mui/material";
import axios from "axios";
import DataTable from "../../components/DataTable";
import CommandMessages from "../../components/CommandMessages";
import ReportChart from "../../components/ReportChart";
import "react-toastify/dist/ReactToastify.css";

const FETCH_MESSAGES_URL = `${process.env.REACT_APP_BASE_URL_API}/messages/microservices/messages`;
const FETCH_MESSAGE_STATS_URL = `${process.env.REACT_APP_BASE_URL_API}/messages/microservices/messageStatistics`;
const STATISTICS_INITIAL_DATA = {
  success: 0,
  queue: 0,
  error: 0,
  otherFailure: 0,
  sent: 0,
  received: 0,
  seen: 0,
}

export default function App() {
  return <QueryParams />;
}

function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

function QueryParams() {
  const [loadingResend, setLoadingResend] = useState(false);
  const [initialLoadingMessages, setInitialLoadingMessages] = useState(true);
  const [initialLoadingStatistics, setInitialLoadingStatistics] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [statusFilter, setStatusFilter] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);

  const [messages, setMessages] = useState({ messages: [] });
  const [statistics, setStatistics] = useState(STATISTICS_INITIAL_DATA);

  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateInterval, setDateInterval] = useState([null, null]);
  const [automaticallyFetchMessages, setAutomaticallyFetchMessages] = useState(false);
  const [aviso, setAviso] = useState([]);
  const [isBrowserTabActive, setIsBrowserTabActive] = useState(true)
  const autoFetchIntervalId = useRef(undefined);
  const messagesComponentRef = useRef();
  const isMessagesComponentVisible = useIsVisible(messagesComponentRef)

  const getMessages = async () => {
    try {
      const api = getParam("api")
      const secret = getParam("secret")
      const offset = (currentPage - 1) * itemsPerPage
      const limit = itemsPerPage
      const options = {
        params: {
          api,
          secret,
          offset,
          limit,
          status: statusFilter !== "all" ? statusFilter : undefined
        },
      }
      const response = await axios.get(FETCH_MESSAGES_URL, options)
      setMessages({ messages: response?.data?.messages || [] })

      if(initialLoadingMessages) {
        setInitialLoadingMessages(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getStatistics = async () => {
    try {
      const api = getParam("api")
      const secret = getParam("secret")
      const offset = 0
      const limit = 30000
      const options = {
        params: {
          api,
          secret,
          offset,
          limit
        },
      }
      const response = await axios.get(FETCH_MESSAGE_STATS_URL, options)
      setStatistics(response?.data?.statistics || STATISTICS_INITIAL_DATA)

      if(initialLoadingStatistics) {
        setInitialLoadingStatistics(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getMessagesLoading = async () => {
    setLoadingMessages(true)
    await delay(1000)
    await getMessages()
    setLoadingMessages(false)
  }

  const clearAutoFetchInterval = () => {
    if (autoFetchIntervalId.current) {
      window.clearInterval(autoFetchIntervalId.current)
    }
  }

  useEffect(() => {
    getMessages()
    getStatistics()
  }, [])

  const onBrowserTabInactive = () => {
    setIsBrowserTabActive(false)
  }

  const onBrowserTabActive = () => {
    setIsBrowserTabActive(true)
  }

  useVisibilityChange({ onShow: onBrowserTabActive, onHide: onBrowserTabInactive });

  useEffect(() => {
    clearAutoFetchInterval()
    if (automaticallyFetchMessages && isBrowserTabActive && isMessagesComponentVisible) {
      autoFetchIntervalId.current = window.setInterval(() => {
        getMessages()
        getStatistics()
      }, 10000);
    }

    return () => {
      clearAutoFetchInterval()
    }
  }, [automaticallyFetchMessages, isBrowserTabActive, isMessagesComponentVisible]);

  useEffect(() => {
    getMessagesLoading()
  }, [itemsPerPage, currentPage, statusFilter]);

  useEffect(() => {
    getAviso();
  }, []);

  const getAviso = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL_API}/notifications`)
      .then((res) => {
        setAviso(res.data);
      });
  };

  const handleRequestExportReport = (data) => {
    const messageLimit = data.maxMessageNumber ?? 1000;
    const { phoneLike, messageLike, dateInterval } = data;

    setLoadingExport(true);

    const downloadAsTable = (messages) => {
      const csvBlob = new Blob([
        messages
          .map(m => {
            let ack = "not_sent";
            switch (m.ack) {
              case 0:
                ack = "not_sent";
                break;
              case 1:
                ack = "sent";
                break;
              case 2:
                ack = "read";
                break;
            }
            return {
              phone: m.phone,
              message: `${JSON.stringify(m.message)}`,
              sendAt: m.sendAt,
              ack,
              status: m.status,
            };
          })
          .map(message => `"=""${message.phone}""",${message.message},${message.sendAt},${message.ack},${message.status}`)
          .reduce((acc, current) => `${acc}\n${current}`, "SEP=,\nphone,message,sent_at,ack,status")
      ], { type: "text/csv" });

      const downloadLink = document.createElement('a');

      downloadLink.download = 'report.csv';
      downloadLink.href = window.URL.createObjectURL(csvBlob);
      downloadLink.style.display = 'none';

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(downloadLink.href);
    };

    const url = `${
      process.env.REACT_APP_BASE_URL_API
    }/messages/microservices/messages`;

    let params = {
      api: getParam("api"),
      secret: getParam("secret"),
      offset: 0,
      limit: messageLimit,
    };

    if (messageLike) {
      params.messageLike = messageLike;
    }
    if (phoneLike) {
      params.phoneLike = phoneLike;
    }
    if (dateInterval?.length === 2) {
      dateInterval[0].setHours(0, 0, 0, 0);
      dateInterval[1].setHours(24, 0, 0, 0);

      params.dateBegin = dateInterval[0].toISOString();
      params.dateEnd = dateInterval[1].toISOString();
    }

    var options = {
      method: "GET",
      url: url,
      params,
    };

    axios
      .request(options)
      .then(function (response) {
        downloadAsTable(response.data.messages);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  return (
    <>
      {aviso.map(
        (item) =>
          item.isActive && (
            <Alert severity={item.type} style={{ marginTop: 15 }}>
              <AlertTitle>{item.title}</AlertTitle>
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </Alert>
          )
      )}
      <CommandMessages getMessages={getMessages} />
      <ReportChart />
      <div ref={messagesComponentRef}>
        {
          initialLoadingMessages
          ?
          <Paper
            elevation={4}
            style={{
              margin: 10,
              padding: 20,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: 600,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>Carregando</p>
            </Box>
          </Paper>
          :
          <DataTable
            loadingResend={loadingResend}
            loadingMessages={loadingMessages}
            loadingStatistics={initialLoadingStatistics}
            messages={messages}
            statistics={statistics}
            Filter={getMessages}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            dateInterval={dateInterval}
            setDateInterval={setDateInterval}
            setItemsPerPage={setItemsPerPage}
            itemsPerPage={itemsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            automaticallyFetchMessages={automaticallyFetchMessages}
            setAutomaticallyFetchMessages={setAutomaticallyFetchMessages}
            onRequestExportReport={handleRequestExportReport}
            loadingExport={loadingExport}
          />
        }
      </div>
    </>
  );
}
