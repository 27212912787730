import React from "react";

import { Paper, Button, Typography } from "@mui/material";

function TermsOfUse() {
  const stylePaper = {
    margin: 10,
    padding: 30,
  };

  return (
    <Paper elevation={6} style={stylePaper}>
      <Typography variant="h5" component="div" align="center" gutterBottom>
        TERMOS DE USO
      </Typography>
      <Typography variant="subtitle1" gutterBottom component="div">
        Faça a leitura do QRCode com seu Telefone/WhatsApp, para realizar os envios através do mesmo. <b style={{ color: "red" }}>Atente-se</b> a ler as dicas
        mencionadas abaixo para um correto funcionamento do sistema e também
        para prevenir bloqueios do seu WhatsApp.
      </Typography>

      <br />

      <Typography variant="subtitle1" gutterBottom component="div">
        <strong>Como não usar sua API:</strong>
      </Typography>
      <Typography variant="subtitle1" gutterBottom component="div">
        <ul>
          <li>
            ❌ <b>Não utilizar números que já tenham sido banidos;</b>
          </li>
          <li>
            ❌ Não clique em "Sair de todas as sessões" no celular;
          </li>
          <li>
            ❌ <b>Não ative um número e já faça envios em massa. Considere usar números ativos a mais de 2 meses no WhatsApp;</b>
          </li>
          <li>
            ❌ Seu número pode ser banido se muitos clientes denunciarem como SPAM;
          </li>
        </ul>
      </Typography>

      <br />

      <Typography variant="subtitle1" gutterBottom component="div">
        <strong>Dicas de uso para sua API:</strong>
      </Typography>
      <Typography variant="subtitle1" gutterBottom component="div">
        <ul>
          <li>
            ✅ Conecte-se ao WhatsApp Web antes de conectar a API;
          </li>
          <li>
            ✅ <b>Recomendamos números ativos há mais de 2 meses no WhatsApp;</b>
          </li>
          <li>
            ✅ <b>Use versões oficiais do WhatsApp (WhatsApp ou WhatsApp Business);</b>
          </li>
          <li>
            ✅ Entre em grupos do WhatsApp (Grupos do seu provedor e/uo grupo de suporte da sua empresa);
          </li>
          <li>
            ✅ Troque mensagens com pelo menos 5 contatos e estabeleça um diálogo breve com os mesmos;
          </li>
          <li>
            ✅ Poste status no WhatsApp, aqui não se preocupe com as visualizações nesses status, o intuito é apenas movimentar sua conta;
          </li>
          <li>
            ✅ <b>Após ler o QR Code, aguarde a sincronização completar e mostrar autenticado;</b>
          </li>
          <li>
            ✅ <b>Não use WhatsApp clonado por apps de terceiros (SuperClone, Parallel, etc);</b>
          </li>
          <li>
            ✅ <b>Mantenha seu WhatsApp atualizado! Versões antigas podem causar problemas na API;</b>
          </li>
        </ul>
      </Typography>
    </Paper>
  );
}

export default TermsOfUse;
