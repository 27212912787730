import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RangeDate from "./SelectRangeDate";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";

import { TimePicker as OutTimePicker, ConfigProvider } from "antd";
import pt_BR from "antd/locale/pt_BR";
import dayjs from "dayjs";

function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

export default function BasicTimePicker() {
  const matches = useMediaQuery("(min-width:480px)");

  useEffect(() => {
    const initialValue = document.body.style.zoom;
    if (matches) document.body.style.zoom = "100%";
    else document.body.style.zoom = "50%";
  }, [matches]);

  const [isError, setIsError] = React.useState(false);
  const [msg, setMsg] = React.useState("");

  const [workScheduleEnable, setWorkScheduleEnable] = React.useState(false);

  let dateAuxStart = new Date();
  dateAuxStart.setHours(8);
  dateAuxStart.setMinutes(0);
  dateAuxStart.setSeconds(0);
  let dateAuxEnd = new Date();
  dateAuxEnd.setHours(18);
  dateAuxEnd.setMinutes(0);
  dateAuxEnd.setSeconds(0);

  const [segundaStart, setSegundaStart] = React.useState(dateAuxStart);
  const [segundaEnd, setSegundaEnd] = React.useState(dateAuxEnd);

  const [tercaStart, setTercaStart] = React.useState(dateAuxStart);
  const [tercaEnd, setTercaEnd] = React.useState(dateAuxEnd);

  const [quartaStart, setQurtaStart] = React.useState(dateAuxStart);
  const [quartaEnd, setQurtaEnd] = React.useState(dateAuxEnd);

  const [quintaStart, setQuintaStart] = React.useState(dateAuxStart);
  const [quintaEnd, setQuintaEnd] = React.useState(dateAuxEnd);

  const [sextaStart, setSextaStart] = React.useState(dateAuxStart);
  const [sextaEnd, setSextaEnd] = React.useState(dateAuxEnd);

  const [sabStart, setSabStart] = React.useState(dateAuxStart);
  const [sabEnd, setSabEnd] = React.useState(dateAuxEnd);

  const [domStart, setDomStart] = React.useState(dateAuxStart);
  const [domEnd, setDomEnd] = React.useState(dateAuxEnd);

  const [selectAction, setSelectAction] = React.useState("");
  const [isLoading, setLoading] = React.useState(true);

  const [dom, setDom] = React.useState(false);
  const [seg, setSeg] = React.useState(false);
  const [ter, setTer] = React.useState(false);
  const [qua, setQua] = React.useState(false);
  const [qui, setQui] = React.useState(false);
  const [sex, setSex] = React.useState(false);
  const [sab, setSab] = React.useState(false);

  // handleSetSegundaStart,
  // handleSetSegundaEnd,
  // handleSetTercaStart,
  // handleSetTercaEnd,
  // handleSetQuartaStart,
  // handleSetQuartaEnd,
  // handleSetQuintaStart,
  // handleSetQuintaEnd,
  // handleSetSextaStart,
  // handleSetSextaEnd,
  // handleSetSabStart,
  // handleSetSabEnd,
  // handleSetDomStart,
  // handleSetDomEnd,

  const formatHour = (h) => {
    let d = new Date();
    d.setHours(parseInt(h.split(":")[0]));
    d.setMinutes(parseInt(h.split(":")[1]));
    return d;
  };

  const format = (dat) => {
    let hours = dat.getHours();
    let minuts = dat.getMinutes();
    if (minuts <= 9) {
      minuts = "0" + minuts;
    }
    if (hours <= 9) {
      hours = "0" + hours;
    }
    return hours + ":" + minuts;
  };

  const saveSchuled = () => {
    let days = [];

    if (dom && domStart !== null && domEnd !== null) {
      days.push({
        day: 0,
        startTime: format(domStart),
        endTime: format(domEnd),
      });
    }

    if (seg && segundaStart !== null && segundaEnd !== null) {
      days.push({
        day: 1,
        startTime: format(segundaStart),
        endTime: format(segundaEnd),
      });
    }

    if (ter && tercaStart !== null && tercaEnd !== null) {
      days.push({
        day: 2,
        startTime: format(tercaStart),
        endTime: format(tercaEnd),
      });
    }
    if (qua && quartaStart !== null && quartaEnd !== null) {
      days.push({
        day: 3,
        startTime: format(quartaStart),
        endTime: format(quartaEnd),
      });
    }
    if (qui && quintaStart !== null && quartaEnd !== null) {
      days.push({
        day: 4,
        startTime: format(quintaStart),
        endTime: format(quintaEnd),
      });
    }

    if (sex && sextaStart !== null && sextaEnd !== null) {
      days.push({
        day: 5,
        startTime: format(sextaStart),
        endTime: format(sextaEnd),
      });
    }

    if (sab && sabStart !== null && sabEnd !== null) {
      days.push({
        day: 6,
        startTime: format(sabStart),
        endTime: format(sabEnd),
      });
    }

    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/changeOfficeHourService`,
      headers: { "Content-Type": "application/json" },
      data: {
        clientApi: getParam("api"),
        clientSecret: getParam("secret"),
        officeHourService: {
          active: workScheduleEnable,
          outOfHoursAction: selectAction,
          days: days,
        },
      },
    };

    axios
      .request(options)
      .then(function (response) {
        setIsError(true);
        setMsg(response.data.message);
      })
      .catch(function (error) {
        setIsError(false);
        setMsg("Erro ao processar a requisição!");
        console.error(error);
      });
  };

  const getInfo = async () => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/informations`,
      headers: { "Content-Type": "application/json" },
      data: {
        clientApi: getParam("api"),
        clientSecret: getParam("secret"),
      },
    };

    await axios
      .request(options)
      .then(function (response) {
        let res = response.data;
        let officeHourService = res.officeHourService;

        let days = officeHourService.days;

        days.map((day) => {
          if (day.day === 0) {
            setDomStart(formatHour(day.startTime));
            setDomEnd(formatHour(day.endTime));
            setDom(true);
          }
          if (day.day === 1) {
            setSegundaStart(formatHour(day.startTime));
            setSegundaEnd(formatHour(day.endTime));
            setSeg(true);
          }
          if (day.day === 2) {
            setTercaStart(formatHour(day.startTime));
            setTercaEnd(formatHour(day.endTime));
            setTer(true);
          }
          if (day.day === 3) {
            setQurtaStart(formatHour(day.startTime));
            setQurtaEnd(formatHour(day.endTime));
            setQua(true);
          }
          if (day.day === 4) {
            setQuintaStart(formatHour(day.startTime));
            setQuintaEnd(formatHour(day.endTime));
            setQui(true);
          }
          if (day.day === 5) {
            setSextaStart(formatHour(day.startTime));
            setSextaEnd(formatHour(day.endTime));
            setSex(true);
          }
          if (day.day === 6) {
            setSabStart(formatHour(day.startTime));
            setSabEnd(formatHour(day.endTime));
            setSab(true);
          }
        });

        setWorkScheduleEnable(officeHourService.active);

        setSelectAction(officeHourService.outOfHoursAction);
        setLoading(false);
      })
      .catch(function (error) { });
  };

  useEffect(() => {
    getInfo();
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ConfigProvider locale={pt_BR}>
      <Box p={10} boxShadow={4}>
        <Grid
          container
          spacing={3}
          justify="center"
          style={{ minHeight: "20vh", width: "100%" }}
        >
          <Grid item xs={12}>
            {msg !== "" && (
              <Alert severity={isError ? "success" : "error"}>{msg}</Alert>
            )}
          </Grid>
          <Grid item xs={4} align="center"></Grid>
          <Grid>
            <FormGroup>
              <Typography variant="h6">Expediente de Envios:</Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={workScheduleEnable}
                    onChange={() => {
                      setWorkScheduleEnable(!workScheduleEnable);
                    }}
                  />
                }
                label="Clique para ativar ou desativar."
              />
              <Typography variant="h6">
                Qual ação deve ser executada?
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  label="Configuração"
                  value={selectAction}
                  onChange={(value) => {
                    setSelectAction(value.target.value);
                  }}
                >
                  <MenuItem value={"abort"}>Cancelar os envios</MenuItem>
                  <MenuItem value={"send"}>
                    Enviar no próximo expediente
                  </MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
          </Grid>
          <RangeDate
            setSeg={setSeg}
            setTer={setTer}
            setQua={setQua}
            setQui={setQui}
            setSex={setSex}
            setSab={setSab}
            setDom={setDom}
            seg={seg}
            ter={ter}
            qua={qua}
            qui={qui}
            sex={sex}
            sab={sab}
            dom={dom}
          />
          <>
            {dom && (
              <Grid item xs={12} align="center">
                <Grid container>
                  <Grid item xs={12}>
                    <label style={{ margin: 5 }}>Dom</label>
                    <OutTimePicker
                      status={domEnd <= domStart ? 'error' : undefined}
                      onChange={(hours) => setDomStart(new Date(hours))}
                      value={dayjs(domStart)}
                    />
                    <label style={{ margin: 10 }}>até</label>
                    <OutTimePicker
                      status={domEnd <= domStart ? 'error' : undefined}
                      onChange={(hours) => setDomEnd(new Date(hours))}
                      value={dayjs(domEnd)}
                    />
                  </Grid>
                  { domEnd <= domStart && (
                    <Grid item xs={12}>
                      <Typography fontSize={"8pt"} color="red">Hora final não pode ser menor que inicial</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {seg && (
              <Grid item xs={12} align="center">
                <Grid container>
                  <Grid item xs={12}>
                    <label style={{ margin: 5 }}>Seg</label>
                    <OutTimePicker
                      status={segundaEnd <= segundaStart ? 'error' : undefined}
                      onChange={(hours) => setSegundaStart(new Date(hours))}
                      value={dayjs(segundaStart)}
                    />
                    <label style={{ margin: 10 }}>até</label>
                    <OutTimePicker
                      status={segundaEnd <= segundaStart ? 'error' : undefined}
                      onChange={(hours) => setSegundaEnd(new Date(hours))}
                      value={dayjs(segundaEnd)}
                    />
                  </Grid>
                  { segundaEnd <= segundaStart && (
                    <Grid item xs={12}>
                      <Typography fontSize={"8pt"} color="red">Hora final não pode ser menor que inicial</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {ter && (
              <Grid item xs={12} align="center">
                <Grid container>
                  <Grid item xs={12}>
                    <label style={{ margin: 5 }}>Ter</label>
                    <OutTimePicker
                      status={tercaEnd <= tercaStart ? 'error' : undefined}
                      onChange={(hours) => setTercaStart(new Date(hours))}
                      value={dayjs(tercaStart)}
                    />
                    <label style={{ margin: 10 }}>até</label>
                    <OutTimePicker
                      status={tercaEnd <= tercaStart ? 'error' : undefined}
                      onChange={(hours) => setTercaEnd(new Date(hours))}
                      value={dayjs(tercaEnd)}
                    />
                  </Grid>
                  { tercaEnd <= tercaStart && (
                    <Grid item xs={12}>
                      <Typography fontSize={"8pt"} color="red">Hora final não pode ser menor que inicial</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {qua && (
              <Grid item xs={12} align="center">
                <Grid container>
                  <Grid item xs={12}>
                    <label style={{ margin: 5 }}>Qua</label>
                    <OutTimePicker
                      status={quartaEnd <= quartaStart ? 'error' : undefined}
                      onChange={(hours) => setQurtaStart(new Date(hours))}
                      value={dayjs(quartaStart)}
                    />
                    <label style={{ margin: 10 }}>até</label>
                    <OutTimePicker
                      status={quartaEnd <= quartaStart ? 'error' : undefined}
                      onChange={(hours) => setQurtaEnd(new Date(hours))}
                      value={dayjs(quartaEnd)}
                    />
                  </Grid>
                  { quartaEnd <= quartaStart && (
                    <Grid item xs={12}>
                      <Typography fontSize={"8pt"} color="red">Hora final não pode ser menor que inicial</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {qui && (
              <Grid item xs={12} align="center">
                <Grid container>
                  <Grid item xs={12}>
                    <label style={{ margin: 5 }}>Qui</label>
                    <OutTimePicker
                      status={quintaEnd <= quintaStart ? 'error' : undefined}
                      onChange={(hours) => setQuintaStart(new Date(hours))}
                      value={dayjs(quintaStart)}
                    />
                    <label style={{ margin: 10 }}>até</label>
                    <OutTimePicker
                      status={quintaEnd <= quintaStart ? 'error' : undefined}
                      onChange={(hours) => setQuintaEnd(new Date(hours))}
                      value={dayjs(quintaEnd)}
                    />
                  </Grid>
                  { quintaEnd <= quintaStart && (
                    <Grid item xs={12}>
                      <Typography fontSize={"8pt"} color="red">Hora final não pode ser menor que inicial</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {sex && (
              <Grid item xs={12} align="center">
                <Grid container>
                  <Grid item xs={12}>
                    <label style={{ margin: 5 }}>Sex</label>
                    <OutTimePicker
                      status={sextaEnd <= sextaStart ? 'error' : undefined}
                      onChange={(hours) => setSextaStart(new Date(hours))}
                      value={dayjs(sextaStart)}
                    />
                    <label style={{ margin: 10 }}>até</label>
                    <OutTimePicker
                      status={sextaEnd <= sextaStart ? 'error' : undefined}
                      onChange={(hours) => setSextaEnd(new Date(hours))}
                      value={dayjs(sextaEnd)}
                    />
                  </Grid>
                  { sextaEnd <= sextaStart && (
                    <Grid item xs={12}>
                      <Typography fontSize={"8pt"} color="red">Hora final não pode ser menor que inicial</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {sab && (
              <Grid item xs={12} align="center">
                <Grid container>
                  <Grid item xs={12}>
                    <label style={{ margin: 5 }}>Sáb</label>
                    <OutTimePicker
                      status={sabEnd <= sabStart ? 'error' : undefined}
                      onChange={(hours) => setSabStart(new Date(hours))}
                      value={dayjs(sabStart)}
                    />
                    <label style={{ margin: 10 }}>até</label>
                    <OutTimePicker
                      status={sabEnd <= sabStart ? 'error' : undefined}
                      onChange={(hours) => setSabEnd(new Date(hours))}
                      value={dayjs(sabEnd)}
                    />
                  </Grid>
                  { sabEnd <= sabStart && (
                    <Grid item xs={12}>
                      <Typography fontSize={"8pt"} color="red">Hora final não pode ser menor que inicial</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </>
          <br />
          <br />
          <br />
          <br />
          <Grid tem xs={5} align="center"></Grid>
          <Button
            disabled={
              (seg && (segundaEnd <= segundaStart)) ||
              (ter && (tercaEnd <= tercaStart)) ||
              (qua && (quartaEnd <= quartaStart)) ||
              (qui && (quintaEnd <= quintaStart)) ||
              (sex && (sextaEnd <= sextaStart)) ||
              (sab && (sabEnd <= sabStart)) ||
              (dom && (domEnd <= domStart))
            }
            sx={{mt: "16px"}}
            variant="contained"
            onClick={() => saveSchuled()}
            disableElevation
          >
            Salvar expediente
          </Button>
        </Grid>
      </Box>
    </ConfigProvider>
  );
}
