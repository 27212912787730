import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Paper, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { RestartAlt, QrCodeScanner } from "@mui/icons-material";

import axios from "axios";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ManagementInstance() {
  return <QueryParams />;
}

function QueryParams() {
  const [openMessegeSuccess, setOpenMessegeSuccess] = useState(false);
  const [openMessegeError, setOpenMessegeError] = useState(false);
  const [loadingDisconnect, setLoadingDisconnect] = useState(false);
  const [loadingReboot, setLoadingReboot] = useState(false);
  const [activeDisconnect, setActiveDisconnect] = useState(true);
  const [activeReboot, setActiveReboot] = useState(true);

  let query = useQuery();

  const handleChangeDisconnectInstance = () => {
    setLoadingDisconnect(true);
    const disconnectInstance = async () => {
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/expireQRCode`,
        headers: { "Content-Type": "application/json" },
        data: {
          clientApi: query.get("api"),
          clientSecret: query.get("secret"),
        },
      };

      axios
        .request(options)
        .then(function (response) {
          setOpenMessegeSuccess(true);
          setActiveDisconnect(false);
          setLoadingDisconnect(false);
          setTimeout(() => {
            setActiveDisconnect(true);
          }, 300000);
        })
        .catch(function (error) {
          setOpenMessegeSuccess(true);
        });
    };
    disconnectInstance();
  };

  const handleChangeRebootInstance = () => {
    setLoadingReboot(true);
    const rebootInstance = async () => {
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/reboot`,
        headers: { "Content-Type": "application/json" },
        data: {
          clientApi: query.get("api"),
          clientSecret: query.get("secret"),
        },
      };
      setTimeout(() => {
        axios
          .request(options)
          .then(function (response) {
            setOpenMessegeSuccess(true);
            setActiveReboot(false);
            setLoadingReboot(false);
            setTimeout(() => {
              setActiveReboot(true);
            }, 300000);
          })
          .catch(function (error) {
            setOpenMessegeError(true);
          });
      }, 1000);
    };
    rebootInstance();
  };

  const stylePaper = {
    margin: 10,
    padding: 10,
  };
  return (
    <Paper elevation={6} style={stylePaper}>
      <Stack
        spacing={4}
        justifyContent="center"
        alignItems="center"
        direction="row"
        style={{ paddingTop: 10, PaddingEnd: 5 }}
      >
        <LoadingButton
          loading={loadingDisconnect}
          loadingPosition="start"
          disabled={!activeDisconnect}
          startIcon={<QrCodeScanner />}
          onClick={handleChangeDisconnectInstance}
          variant="outlined"
        >
          Expirar QRCode
        </LoadingButton>
        <LoadingButton
          loading={loadingReboot}
          loadingPosition="start"
          disabled={!activeReboot}
          startIcon={<RestartAlt />}
          onClick={handleChangeRebootInstance}
          variant="outlined"
        >
          Reiniciar API
        </LoadingButton>
      </Stack>
    </Paper>
  );
}

export default ManagementInstance;
