import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import { ChartLine } from "./chartLine";
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import jsPDF from "jspdf";
import { Buffer } from "buffer";

function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

function ReportChart() {
  const [infoChart, setInfoChart] = useState([]);
  const [numberOfDays, setNumberOfDays] = useState(30);

  const chartGraphic = infoChart ? (
    infoChart.length > 0 ? (
      <ChartLine data={infoChart} maxHeight={100} />
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Skeleton style={{ width: "80%", height: 200 }} animation="wave" />
      </div>
    )
  ) : null;

  const getInfoChart = useCallback(async (numDays) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/informations`,
      headers: { "Content-Type": "application/json" },
      data: { clientApi: getParam("api"), clientSecret: getParam("secret") },
    };
    axios
      .request(options)
      .then(function (response) {
        if (response.data.shippingReport.description.length === 0) {
          var today = new Date();
          var start = new Date(new Date().setDate(today.getDate() - numDays));
          start.setHours(start.getHours() + 3);
          let end = new Date(new Date().setDate(today.getDate() + 2));
          end.setHours(end.getHours() + 3);

          let params = {
            gte: start,
            lte: end,
          };
          const url = `${
            process.env.REACT_APP_BASE_URL_API
          }/messages/microservices/messages?api=${getParam("api")}&secret=${getParam(
            "secret"
          )}&offset=${(1 - 1) * 100000}&limit=${100000}`;

          var options = {
            method: "GET",
            url: url,
            params: params,
          };

          axios
            .request(options)
            .then(function (response) {
              let data = response.data.messages;
              if (data.length < 1) {
                return;
              }
              let days = [];
              data.map((item, key) => {
                days.push(new Date(item.created_at).getDate());
              });

              const result = [["Dia", "Envios com erro", "Envios com sucesso"]];
              let uniq = [...new Set(days)];
              uniq.reverse();
              for (const day of uniq) {
                let sucess = getSuccess(data, day);
                let erro = getErro(data, day);
                result.push(["" + day + "", erro, sucess]);
              }
              setInfoChart(result);
            })
            .catch(function (error) {
              console.error(error);
            });
        } else {
          let dataLen = response.data.shippingReport.data.length;
          let numSkips = dataLen - numDays;
          setInfoChart(
            response.data.shippingReport.description.concat(
              response.data.shippingReport.data
                .filter((el, index) => index >= numSkips)
                .map((el) => [el[0].toString(), el[1], el[2]])
            )
          );
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    getInfoChart(numberOfDays);
  }, [getInfoChart, numberOfDays]);

  const getSuccess = (array, day) => {
    let sucess = 0;
    array.map((item, key) => {
      let current = new Date(item.created_at).getDate();
      if (current === day && item.status === "success") {
        sucess++;
      }
    });
    return sucess;
  };
  const getQueue = (array, day) => {
    let sucess = 0;
    array.map((item, key) => {
      let current = new Date(item.created_at).getDate();
      if (current === day && item.status === "queue") {
        sucess++;
      }
    });
    return sucess;
  };
  const getErro = (array, day) => {
    let sucess = 0;
    array.map((item, key) => {
      let current = new Date(item.created_at).getDate();
      if (current === day && item.status === "erro") {
        sucess++;
      }
    });
    return sucess;
  };

  const stylePaper = {
    margin: 10,
    padding: 20,
  };

  const exportGraphic = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      format: "a4",
      unit: "mm",
    });

    const chartContainer = document.getElementById(
      "x-chart-graphic-to-export-id"
    );
    const svgChart = chartContainer.getElementsByTagName("svg")[0];
    if (svgChart) {
      var width = svgChart.width.baseVal.value;
      var height = svgChart.height.baseVal.value;

      const chartPictureRatioHW = height / width;

      const partialSvgString = svgChart.innerHTML;
      const fullSvgContent = `
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="${width}px"
          height="${height}px"
        >
        ${partialSvgString}
        </svg>
      `;

      const svgContentBase64 = Buffer.from(fullSvgContent).toString("base64");
      const chartImg = new Image();
      chartImg.src = "data:image/svg+xml;base64," + svgContentBase64;
      chartImg.onload = () => {
        const svgCanvas = document.createElement("canvas");
        svgCanvas.width = width;
        svgCanvas.height = height;

        const c = svgCanvas.getContext("2d");
        c.drawImage(chartImg, 0, 0);

        // Folha A4 tem 297mm no lado maior, aqui estou pegando algo 8mm menor
        const mmWidth = 269;
        // A altura é escolhida de acordo com o "aspect ratio" do gráfico
        const mmHeight = mmWidth * chartPictureRatioHW;

        const graphTitle = "Gráfico de Envios - API";
        const fontSize = doc.internal.getFontSize();
        const pageWidth = doc.internal.pageSize.width;
        const textWidth =
          (doc.getStringUnitWidth(graphTitle) * fontSize) /
          doc.internal.scaleFactor;
        const xTextCoord = (pageWidth - textWidth) / 2;
        doc.setFont("helvetica", "", "bold");
        doc.setFontSize("16");
        doc.text(graphTitle, xTextCoord, 30);
        doc.addImage(
          svgCanvas.toDataURL("image/png"),
          "PNG",
          16,
          40,
          mmWidth,
          mmHeight
        );

        const endDate = new Date();
        const beginDate = new Date();
        // https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Date/setDate
        beginDate.setDate(endDate.getDate() - 5);

        const dateOpts = {
          dateStyle: "short",
        };

        const beginDateStr = beginDate
          .toLocaleDateString("pt-BR", dateOpts)
          .replace(/[/]/g, "_");
        const endDateStr = endDate
          .toLocaleDateString("pt-BR", dateOpts)
          .replace(/[/]/g, "_");
        doc.save(`grafico_de_envios_${beginDateStr}_ate_${endDateStr}.pdf`);
      };
    }
  };

  return (
    <Paper elevation={6} style={stylePaper}>
      <Grid container alignItems="center" spacing={4}>
        <Grid xs></Grid>
        <Grid
          item
          xs={4}
          sm={3}
          display="flex"
          justifyContent="center"
          direction="column"
        >
          <Typography textAlign="center">Relatório de envio</Typography>
          <Select
            value={numberOfDays}
            onChange={(e) => setNumberOfDays(e.target.value)}
          >
            <MenuItem value={7}>Últimos 7 dias</MenuItem>
            <MenuItem value={15}>Últimos 15 dias</MenuItem>
            <MenuItem value={30}>Últimos 30 dias</MenuItem>
          </Select>
        </Grid>
        <Grid
          item
          xs={4}
          sm={3}
          display="flex"
          justifyContent="center"
          direction="column"
        >
          <Button variant="outlined" onClick={() => exportGraphic()}>
            Exportar Gráfico
          </Button>
        </Grid>
        <Grid xs></Grid>
      </Grid>
      <div id="x-chart-graphic-to-export-id">{chartGraphic}</div>
    </Paper>
  );
}

export default ReportChart;
