const variaveisIntegracao = [
    {
        sistema: 'IXC', variaveis: [
            '#cliente_razao#',
            '#cliente_fantasia#',
            '#cliente_nome#',
            '#cliente_cnpj_cpf#',
            '#cliente_email#',
            '#areceber_numero#',
            '#areceber_data_emissao#',
            '#areceber_data_vencimento#',
            '#areceber_valor#',
            '#areceber_valor_aberto#',
            '#areceber_valor_atual#',
            '#areceber_linha_digitavel#',
            '#cobranca_numero#',
            '#cobranca_data#',
            '#cobranca_status#',
            '#cobranca_data_proxima_acao#',
            '#data_recebido#',
            '#valor_recebido#',
            '#monitoramento_host_nome#',
            '#monitoramento_host_ip#',
            '#monitoramento_host_data#',
            '#monitoramento_host_status#',
            '#link_gateway#'
        ]
    },
    {
        sistema: 'WHMCS', variaveis: [
            '%valortitulo%',
            '%tipotit%',
            '%obstit%',
            '%senhacliente%',
            '%numerotitulo%',
            '%nomeresumido%',
            '%linhadig%',
            '%logincliente%',
            '%vencimento%'
        ]
    },
    {
        sistema: 'SGP',
        variaveis: [
            "link",
            'vencimento',
            'valor',
            'link'
        ]
    }
]

export {
    variaveisIntegracao
}