import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Paper,
  Stack,
  Typography,
  Alert,
  Collapse,
  IconButton,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CleaningServices, Close } from "@mui/icons-material";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import SendIcon from "@mui/icons-material/Send";
import { ToastContainer, toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

const useStyles = makeStyles((theme) => ({
  // button: {
  //   margin: theme.spacing(1),
  //   [theme.breakpoints.down("sm")]: {
  //     minWidth: 32,
  //     paddingLeft: 8,
  //     paddingRight: 8,
  //     "& .MuiButton-startIcon": {
  //       margin: 0,
  //     },
  //   },
  // },

  "@media (min-width: 600px)": {
    buttonText: {
      // display: "none",
    },
  },
}));

function CommandMessages(props) {
  const classes = useStyles();

  const [loadingCleanQueue, setLoadingCleanQueue] = useState(false);
  const [loadingCleanHistoric, setLoadingCleanHistoric] = useState(false);
  const [openMessegeSuccess, setOpenMessegeSuccess] = useState(false);
  const [openMessegeError, setOpenMessegeError] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [hoursResend, setHoursResend] = React.useState(24);
  const [loadingEraseMessages, setLoadingEraseMessages] = useState(false);
  const [hoursToErase, setHoursToErase] = React.useState(24);
  const [stateStatusBar, setStateStatusBar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [open, setOpen] = React.useState(false);

  let query = useQuery();

  const handleChangeCleanQueue = () => {
    setLoadingCleanQueue(true);
    const cleanQueue = async () => {
      const options = {
        method: "DELETE",
        url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/clearQueue`,
        headers: { "Content-Type": "application/json" },
        data: {
          clientApi: query.get("api"),
          clientSecret: query.get("secret"),
        },
      };

      axios
        .request(options)
        .then(function (response) {
          setOpenMessegeSuccess(true);
          props.getMessages();
        })
        .catch(function (error) {
          setOpenMessegeError(true);
        });

      setLoadingCleanQueue(false);
    };
    cleanQueue();
  };

  const handleChange = (event) => {
    setHoursResend(Number(event.target.value) || 24);
    resendMessageError();
    handleClose();
    setLoadingResend(false);
    // setLoadingResend(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickEraseErrorMessages = () => {
    setLoadingEraseMessages(true);
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/messages/microservices/clearErrors`,
      headers: { "Content-Type": "application/json" },
      data: {
        clientApi: getParam("api"),
        clientSecret: getParam("secret"),
        lastHours: parseInt(hoursToErase),
      },
    };

    axios
      .request(options)
      .then(function (response) {
        setTimeout(() => {
          setLoadingEraseMessages(false);
          // toast.success("Mensagem de sucesso");
          setStateStatusBar({
            open: true,
            message: "Mensagens de erro removidas com sucesso!",
            severity: "success",
          });
        }, 5000);
      })
      .catch(function (error) {
        console.error(error);
        setStateStatusBar({
          open: true,
          message: "Falha ao remover mensagens com erro!",
          severity: "error",
        });
      });
    setTimeout(() => {
      setLoadingEraseMessages(false);
      toast.success("Mensagem de sucesso");
    }, 5000);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const resendMessageError = () => {
    setLoadingResend(true);
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/messages/microservices/resendMessageError`,
      headers: { "Content-Type": "application/json" },
      data: {
        clientApi: getParam("api"),
        clientSecret: getParam("secret"),
        lastHours: parseInt(hoursResend),
      },
    };

    axios
      .request(options)
      .then(function (response) {
        setTimeout(() => {
          setLoadingResend(false);
          toast.success("Mensagem de sucesso");
        }, 5000);
      })
      .catch(function (error) {
        console.error(error);
      });
    setTimeout(() => {
      setLoadingResend(false);
      toast.success("Mensagem de sucesso");
    }, 5000);
  };
  const handleChangeCleanHistoric = () => {
    setLoadingCleanHistoric(true);
    const clearHistoric = async () => {
      const options = {
        method: "DELETE",
        url: `${process.env.REACT_APP_BASE_URL_API}/messages/microservices/clearHistoric`,
        headers: { "Content-Type": "application/json" },
        data: {
          clientApi: query.get("api"),
          clientSecret: query.get("secret"),
        },
      };

      axios
        .request(options)
        .then(function (response) {
          setOpenMessegeSuccess(true);
          props.getMessages();
        })
        .catch(function (error) {
          setOpenMessegeError(true);
        });

      setLoadingCleanHistoric(false);
    };
    clearHistoric();
  };

  const handleCloseStateStatusbar = () => {
    setStateStatusBar({
      ...stateStatusBar,
      open: false,
    });
  }

  const stylePaper = {
    margin: 10,
    padding: 10,
  };
  return (
    <Paper elevation={6} style={stylePaper}>
      <Snackbar open={stateStatusBar.open} autoHideDuration={6000} onClose={handleCloseStateStatusbar}>
        <Alert
          onClose={handleCloseStateStatusbar}
          severity={stateStatusBar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {stateStatusBar.message}
        </Alert>
      </Snackbar>
      <Collapse in={openMessegeSuccess}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenMessegeSuccess(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Operação realizada sucesso!
        </Alert>
      </Collapse>
      <Collapse in={openMessegeError}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenMessegeError(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Não foi possível realizar essa operação.
        </Alert>
      </Collapse>
      <Typography variant="h6" align="center" component="div" gutterBottom>
        Ações disponíveis no Relatório de sua API:
      </Typography>
      <Stack
        spacing={4}
        justifyContent="center"
        alignItems="center"
        direction="row"
        style={{ paddingTop: 10, PaddingEnd: 5 }}
      >

        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 8 }}>
          <Grid item xs={2} sm={4}>
            <Tooltip title="Todas mensagens em Fila serão canceladas.">
              <LoadingButton
                fullWidth
                loading={loadingCleanQueue}
                loadingPosition="start"
                startIcon={<CleaningServices />}
                onClick={handleChangeCleanQueue}
                variant="outlined"
              >
                Limpar fila
              </LoadingButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2} sm={4}>
            <Tooltip title="Todo seu relatório será removido (completo).">
              <LoadingButton
                fullWidth
                loading={loadingCleanHistoric}
                loadingPosition="start"
                startIcon={<CleaningServices />}
                onClick={handleChangeCleanHistoric}
                variant="outlined"
              >
                Limpar relatório
              </LoadingButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2} sm={4}>
            <Tooltip title="Reenviar mensagens (com erro) das últimas 24h.">
              <LoadingButton
                fullWidth
                loading={loadingResend}
                onClick={() => {
                  handleClickOpen();
                }}
                loadingPosition="start"
                startIcon={<SendIcon />}
                variant="outlined"
              >
                Reenviar com erro
              </LoadingButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2} sm={4}>
            <Tooltip title="Apagar as mensagens com erro das últimas 24h">
              <LoadingButton
                fullWidth
                loading={loadingEraseMessages}
                onClick={() => {
                  handleClickEraseErrorMessages();
                }}
                loadingPosition="start"
                startIcon={<SendIcon />}
                variant="outlined"
              >
                Apagar as com erro
              </LoadingButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
          <DialogTitle>Reenvio de mensagens</DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl sx={{ m: 1, minWidth: 220 }}>
                <InputLabel htmlFor="demo-dialog-native">
                  Reenviar em:
                </InputLabel>
                <Select
                  native
                  value={hoursResend}
                  onChange={(val) => {
                    setHoursResend(val.target.value);
                  }}
                  input={
                    <OutlinedInput
                      label="Reenviar em:"
                      id="demo-dialog-native"
                    />
                  }
                >
                  <option aria-label="None" value="" />
                  <option value={24}>24h</option>
                  <option value={48}>48h</option>
                  <option value={72}>72h</option>
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleChange}>Enviar</Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Paper>
  );
}

export default CommandMessages;
