import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  useMediaQuery,
  ListItemText,
} from "@mui/material";

import MuiAppBar from "@mui/material/AppBar";

import CodeIcon from "@mui/icons-material/Code";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import MessageIcon from "@mui/icons-material/Message";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ContactsIcon from "@mui/icons-material/Contacts";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import Menssage from "../../pages/Mensagem";
import Relatory from "../../pages/Relatory";
import Instance from "../../pages/Instance";
import Contacts from "../../pages/Contatos/index";
import CustomerContacts from "../../pages/CustomerContacts";
import WorkSchedule from "../../pages/WorkSchedule";

import Info from "../Info/infoAPi";
import Alert from "@mui/material/Alert";

import json2mq from "json2mq";
import Generator from "../../pages/Generator";
import ScheduledMessages from "../../pages/ScheduledMessages";
import { ScheduleSend } from "@mui/icons-material";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#1565c0",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selectValue, setSelectValue] = React.useState(0);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const setSelect = (num) => {
    setSelectValue(num);
    //setOpen(false);
  };
  const matches = useMediaQuery(
    json2mq({
      minWidth: 600,
      minWidth: 1000,
    })
  );

  useEffect(() => {
    setOpen(matches);
  }, [matches]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* {matches ? handleDrawerOpen() : handleDrawerClose()} */}
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <SettingsApplicationsIcon />

            {selectValue === 0 && (
              <span
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  // fontWeight: "bold",
                }}
              >
                Gerenciar API (Clique aqui para abrir o menu)
              </span>
            )}
            {selectValue === 1 && (
              <span
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  //fontWeight: "bold",
                }}
              >
                Relatório de Envios (Clique aqui para abrir o menu)
              </span>
            )}
            {selectValue === 2 && (
              <span
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  //fontWeight: "bold",
                }}
              >
                Envio Manual (Clique aqui para abrir o menu)
              </span>
            )}
            {selectValue === 3 && (
              <span
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  //fontWeight: "bold",
                }}
              >
                Contatos (Clique aqui para abrir o menu)
              </span>
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          {selectValue === 0 && (
            <div
              style={{
                display: "flex",
                width: "100%",
                fontSize: 14,
                alignItems: "flex-start",
                color: "white",
                marginLeft: 10,
                fontWeight: "bold",
              }}
            >
              Gerenciar API
            </div>
          )}
          {selectValue === 1 && (
            <span
              style={{
                display: "flex",
                width: "100%",
                fontSize: 14,
                alignItems: "flex-start",
                color: "white",
                marginLeft: 10,
                fontWeight: "bold",
              }}
            >
              Relatório de Envios
            </span>
          )}
          {selectValue === 2 && (
            <span
              style={{
                display: "flex",
                width: "100%",
                fontSize: 14,
                alignItems: "flex-start",
                color: "white",
                marginLeft: 10,
                fontWeight: "bold",
              }}
            >
              Envio Manual
            </span>
          )}
          {selectValue === 3 && (
            <span
              style={{
                display: "flex",
                width: "100%",
                fontSize: 14,
                alignItems: "flex-start",
                color: "white",
                marginLeft: 10,
                fontWeight: "bold",
              }}
            >
              Contatos
            </span>
          )}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon style={{ color: "white" }} />
            ) : (
              <ChevronRightIcon style={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            button
            onClick={() => {
              setSelect(2);
              !matches && setOpen(false);
            }}
          >
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText primary={"Disparos Manuais"} />
          </ListItem>
        </List>
        <List>
          <ListItem
            button
            onClick={() => {
              !matches && setOpen(false);
              setSelect(1);
            }}
          >
            <ListItemIcon>
              <FactCheckIcon />
            </ListItemIcon>
            <ListItemText primary={"Relatório de Disparos"} />
          </ListItem>
        </List>
        <List>
          <ListItem
            button
            onClick={() => {
              !matches && setOpen(false);
              setSelect(0);
            }}
          >
            <ListItemIcon>
              <QrCodeIcon />
            </ListItemIcon>
            <ListItemText primary={"Gerenciar API"} />
          </ListItem>
        </List>
        <List>
          <ListItem button onClick={() => setSelect(3)}>
            <ListItemIcon>
              <ContactsIcon />
            </ListItemIcon>
            <ListItemText primary={"Contatos & Grupos"} />
          </ListItem>
        </List>
        <List>
          <ListItem button onClick={() => setSelect(4)}>
            <ListItemIcon>
              <ScheduleSend/>
            </ListItemIcon>
            <ListItemText primary={"Disparos agendados"} />
          </ListItem>
        </List>
        <List>
          <ListItem
            button
            onClick={() => {
              !matches && setOpen(false);
              setSelect(5);
            }}
          >
            <ListItemIcon>
              <WatchLaterIcon />
            </ListItemIcon>
            <ListItemText primary={"Expediente de Envios"} />
          </ListItem>
        </List>
        {/* <List>
          <ListItem button onClick={() => {
            !matches && setOpen(false)
            setSelect(5)
          }}>
            <ListItemIcon>
              <CodeIcon />
            </ListItemIcon>
            <ListItemText primary={"Gerador de Mensagem"} />
          </ListItem>
        </List> */}
        <Divider />
        <Info />
      </Drawer>
      <Main open={open}>
        <div style={{ marginTop: 50 }} />
        {selectValue === 0 && <Instance />}
        {selectValue === 1 && <Relatory />}
        {selectValue === 2 && <Menssage />}
        {selectValue === 3 && <CustomerContacts />}
        {selectValue === 4 && <ScheduledMessages />}
        {selectValue === 5 && <WorkSchedule />}
      </Main>
    </Box>
  );
}
