import Menssage from "../Mensagem/";
import Relatory from "../Relatory";
import Instance from "../Instance/";

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Info from "../../components/Info/infoAPi";
import json2mq from "json2mq";
import Drawer from "../../components/Drawer/Drawer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  return (
    <>
      {/* {matches && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Info />
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
            >
              <Tab label="Envio de Mensagem" {...a11yProps(0)} />
              <Tab label="Relatório de Mensagem" {...a11yProps(1)} />
              <Tab label="Gerenciar QRCODE" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Menssage />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Relatory />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Instance />
          </TabPanel>
        </Box>
      )}
      {!matches && <Drawer />} */}

      <Drawer />
    </>
  );
}
