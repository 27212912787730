import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { CircularProgress, Box, Alert, AlertTitle, Modal } from "@mui/material";
import TermsOfUse from "../../components/TermsOfUse";
import GeneratingQrCode from "../../components/GeneratingQrCode";
import NoQrCode from "../../components/NoQrCode";
import QrRead from "../../components/QrRead";
import ManagementInstance from "../../components/ManagementInstance";
import ChangeDisconnectionNotificationNumber from "../../components/ChangeDisconnectionNotificationNumber";
import { seo } from "../../util/seo";
import Reset from "../../components/ResetAPI/";
function Home() {
  return <QueryParams />;
}

function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

async function getInformations() {
  const options = {
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/informations`,
    headers: { "Content-Type": "application/json" },
    data: { clientApi: getParam("api"), clientSecret: getParam("secret") },
  };

  let informations = await axios.request(options);
  return informations.data;
}

function QueryParams() {
  const timerRequest = 2000;

  const [timeInterval, setTimeInterval] = useState(0);
  const [informations, setInformations] = useState(undefined);

  const [aviso, setAviso] = useState([]);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, timerRequest);

  useEffect(() => {
    const asyncExec = async () => {
      setInformations(await getInformations());
    };
    asyncExec();
  }, [timeInterval]);

  useEffect(() => {
    seo({
      title: "Instância",
      metaDescription: "With some meta description",
    });
    getAviso();
  }, []);
  const getAviso = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL_API}/notifications`)
      .then((res) => {
        console.log(res.data);
        setAviso(res.data);
      });
  };

  return (
    <>
      {aviso.map(
        (item) =>
          item.isActive && (
            <Alert severity={item.type} style={{ marginTop: 15 }}>
              <AlertTitle>{item.title}</AlertTitle>
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </Alert>
          )
      )}
      {informations ? (
        <>
          {(() => {
            switch (informations.details.qrcode.status) {
              case "requested":
                return (
                  <>
                    {" "}
                    <TermsOfUse />
                    {informations.user.notificationService.active &&
                      informations.user.notificationService.whatsapp.active && (
                        <ChangeDisconnectionNotificationNumber />
                      )}
                    <Reset />
                    <GeneratingQrCode />
                  </>
                );
              case "use":
                return (
                  <>
                    {" "}
                    {informations.user.notificationService.active &&
                      informations.user.notificationService.whatsapp.active && (
                        <ChangeDisconnectionNotificationNumber />
                      )}
                    <ManagementInstance informations={informations}/>
                    <QrRead />
                  </>
                );
              default:
                // noqrcode
                return (
                  <>
                    {informations.user.notificationService.active &&
                      informations.user.notificationService.whatsapp.active && (
                        <ChangeDisconnectionNotificationNumber />
                      )}
                    <ManagementInstance />
                    <NoQrCode />
                  </>
                );
            }
          })()}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: 600,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress></CircularProgress>
        </Box>
      )}
    </>
  );
}

export default Home;
