import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";

import { variaveisIntegracao } from "./constants";

// icons
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// components
import IconButton from "@mui/material/IconButton";

export default function Generator() {
  const [sistemSelected, setSistemSelected] = useState("");
  const [variaveisList, setVariaveisList] = useState([]);
  const [currentItemListSelected, setCurrentItemListSelected] = useState(-1);
  const [variaveisSelect, setVariaveisSelect] = useState("");

  const [newLineArray, setNewLineArray] = useState([
    {
      filename: "",
      url: "",
      msg: "",
    },
  ]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    const prevValue = variaveisIntegracao.filter(
      (item) => item.sistema === sistemSelected
    );
    if (prevValue.length > 0) setVariaveisList(prevValue[0].variaveis);
  }, [sistemSelected]);

  useEffect(() => {
    let tmp = "";
    newLineArray.map((item) => {
      tmp += `%NewLine%${`${item.msg}${
        item.sistemSelected ? `%App%${item.sistemSelected}%/App%` : ""
      }%SendFile%%Filename%${item.filename}%/Filename%%Url%${
        item.url
      }%/Url%%/SendFile%`}%/NewLine%`;
    });
    setContent(tmp);
  }, [newLineArray]);

  const handleSelectVariable = (item, k) => {
    setCurrentItemListSelected(k);
  };

  const handleDelete = (id) => {
    setNewLineArray((prevState) => prevState.filter((_, k) => k !== id));
  };

  const handleNewLine = () => {
    setNewLineArray((prev) =>
      prev.concat({
        filename: "",
        url: "",
        msg: "",
        sistemSelected: "",
      })
    );
  };

  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Box sx={{ padding: 2, boxShadow: 1 }}>
            <Typography variant="h6" component="h2">
              Escolha o sistema que deseja fazer a integração
            </Typography>
            <FormControl sx={{ marginTop: 2, width: 200 }}>
              <InputLabel id="demo-simple-select-label">
                Sistema a integra
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sistemSelected}
                label="Sistema a integra"
                autoWidth
                placeholder="Sistema a integra"
                onChange={(val) => setSistemSelected(val.target.value)}
              >
                <MenuItem default value={""}>
                  Nenhuma
                </MenuItem>
                {variaveisIntegracao.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.sistema}>
                      {item.sistema}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ padding: 2, boxShadow: 1 }}>
            <Typography variant="h6" component="h2">
              Escolha as variaveis
            </Typography>
            <List
              sx={{
                width: "100%",
                //maxWidth: 360,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: 150,
                "& ul": { padding: 0 },
              }}
              subheader={<li />}
            >
              {variaveisList &&
                variaveisList.map((item, key) => (
                  <ListItem
                    key={key}
                    selected={key === currentItemListSelected}
                    onClick={() => handleSelectVariable(item, key)}
                  >
                    <ListItemText primary={item} />
                    <IconButton
                      onClick={() => navigator.clipboard.writeText(item)}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ boxShadow: 2, marginTop: 5, padding: 2 }}>
        <Typography variant="h6" component="h2">
          Configure o envio da Mensagem
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          <Button
            startIcon={<AddIcon sx={{ color: "white" }} />}
            variant="contained"
            onClick={() => handleNewLine()}
          >
            Nova Linha
          </Button>
        </div>
        {newLineArray.map((item, key) => (
          <Box key={key} sx={{ boxShadow: 2, marginTop: 5, padding: 2 }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  hiddenLabel
                  id="filled-hidden-label-small"
                  //defaultValue="URL"
                  value={newLineArray[key].url}
                  onChange={(val) => {
                    setNewLineArray((prevArr) => {
                      const result = [...prevArr];
                      result[key].sistemSelected = sistemSelected;
                      result[key].url = val.target.value;
                      return result;
                    });
                  }}
                  placeholder="URL"
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  hiddenLabel
                  value={newLineArray[key].filename}
                  //    onChange={(val) => handleMsg(val.target.value, newLineArray.length)}
                  onChange={(val) => {
                    setNewLineArray((prevArr) => {
                      const result = [...prevArr];
                      result[key].sistemSelected = sistemSelected;
                      result[key].filename = val.target.value;
                      return result;
                    });
                  }}
                  id="filled-hidden-label-small"
                  placeholder="Filename ou variável"
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  hiddenLabel
                  id="filled-hidden-label-small"
                  placeholder="Mensagem"
                  value={newLineArray[key].msg}
                  onChange={(val) => {
                    setNewLineArray((prevArr) => {
                      const result = [...prevArr];
                      result[key].sistemSelected = sistemSelected;
                      result[key].msg = val.target.value;
                      return result;
                    });
                  }}
                  rows={5}
                  multiline
                  variant="filled"
                  size="medium"
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  startIcon={<DeleteIcon sx={{ color: "white" }} />}
                  color={"error"}
                  variant="contained"
                  onClick={() => handleDelete(key)}
                >
                  Excluir linha
                </Button>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
      <Box sx={{ boxShadow: 2, marginTop: 5, padding: 2 }}>
        <Typography variant="h6" component="h2">
          Mensagem Gerada
        </Typography>
        <div
          style={{
            background: "#eee",
            overflowWrap: "break-word",
            width: "60vw",
            padding: 10,
          }}
        >
          {content.toString()}
        </div>
        <Button
          sx={{ marginTop: 2 }}
          variant="outlined"
          onClick={() => navigator.clipboard.writeText(content.toString())}
        >
          Copiar
        </Button>
      </Box>
    </div>
  );
}
