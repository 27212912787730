import React, { useState, useEffect } from "react";

import {
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  Alert,
  Stack,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { stylePaper } from "./styles";

function ChangeMultidevices() {
  return <QueryParams />;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function QueryParams() {
  const [activeMultidevices, setActiveMultidevices] = useState(false);

  let query = useQuery();

  const handleChange = (event) => {
    setActiveMultidevices(event.target.checked);
    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/useVersionMultidevice`,
      headers: { "Content-Type": "application/json" },
      data: {
        clientApi: query.get("api"),
        clientSecret: query.get("secret"),
        multidevice: event.target.checked,
      },
    };

    axios.request(options).catch(function (error) {
      console.error(error);
    });
  };

  useEffect(() => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/informations`,
      headers: { "Content-Type": "application/json" },
      data: { clientApi: query.get("api"), clientSecret: query.get("secret") },
    };

    axios
      .request(options)
      .then(function (response) {
        setActiveMultidevices(response.data.multidevice);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <Alert severity="info" color="info">
      <b>Utilize nossa API com seu WhatsApp:</b>
      <br />
      <br />1 - Abra o WhatsApp no seu celular;
      <br />2 - Toque em Mais opções no <b>Android</b>  ou em Configurações  no <b>iPhone;</b>
      <br />3 - Toque em Dispositivos conectados e, em seguida, em Conectar dispositivos;
      <br />4 - Aponte seu celular para essa tela para capturar o QR Code;
      <br />
      <br />
      <b>* Mantenha seu WhatsApp sempre atualizado.</b>
    </Alert>
  );
}

export default ChangeMultidevices;
