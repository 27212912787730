import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Paper,
  Stack,
  Typography,
  Alert,
  Collapse,
  IconButton,
  Modal,
  Card,
  Grid,
  Input,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Close, RestartAlt, QrCodeScanner, Timer, Assessment } from "@mui/icons-material";

import axios from "axios";
import ChangeDailyReportNumber from "../ChangeDailyReportNumber";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ManagementInstance(props) {
  return <QueryParams {...props}/>;
}

function QueryParams(props) {
  const [openMessegeSuccess, setOpenMessegeSuccess] = useState(false);
  const [openMessegeError, setOpenMessegeError] = useState(false);
  const [loadingDisconnect, setLoadingDisconnect] = useState(false);
  const [loadingReboot, setLoadingReboot] = useState(false);
  const [loadingTimerOverride, setLoadingTimerOverride] = useState(false);
  const [activeDisconnect, setActiveDisconnect] = useState(true);
  const [activeReboot, setActiveReboot] = useState(true);
  const [activeTimerOverride, setActiveTimerOverride] = useState(true);
  const [timerOverrideModal, setTimerOverrideModal] = useState(false);
  const [timerOverride, setTimerOverride] = useState(props.informations?.timerOverride);
  const [timerNewLine, setTimerNewLine] = useState(props.informations?.timerNewLine);
  const [timerOverrideError, setTimerOverrideError] = useState("");
  const [timerNewLineError, setTimerNewLineError] = useState("");
  const [timerUpdatedSuccess, setTimerUpdatedSuccess] = useState(false);

  const [activeConfigureDailyReport, setActiveConfigureDailyReport] = useState(true);
  const [configureDailyReportModal, setConfigureDailyReportModal] = useState(false);
  const [loadingConfigureDailyReport, setLoadingConfigureDailyReport] = useState(false);

  // alert(`${JSON.stringify(props.informations)}`);

  let query = useQuery();

  const handleChangeDisconnectInstance = () => {
    setLoadingDisconnect(true);
    const disconnectInstance = async () => {
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/expireQRCode`,
        headers: { "Content-Type": "application/json" },
        data: {
          clientApi: query.get("api"),
          clientSecret: query.get("secret"),
        },
      };

      axios
        .request(options)
        .then(function (response) {
          setOpenMessegeSuccess(true);
          setActiveDisconnect(false);
          setLoadingDisconnect(false);
          setTimeout(() => {
            setActiveDisconnect(true);
          }, 300000);
        })
        .catch(function (error) {
          setOpenMessegeSuccess(true);
        });
    };
    disconnectInstance();
  };

  const handleChangeRebootInstance = () => {
    setLoadingReboot(true);
    const rebootInstance = async () => {
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/reboot`,
        headers: { "Content-Type": "application/json" },
        data: {
          clientApi: query.get("api"),
          clientSecret: query.get("secret"),
        },
      };
      setTimeout(() => {
        axios
          .request(options)
          .then(function (response) {
            setOpenMessegeSuccess(true);
            setActiveReboot(false);
            setLoadingReboot(false);
            setTimeout(() => {
              setActiveReboot(true);
            }, 300000);
          })
          .catch(function (error) {
            setOpenMessegeError(true);
          });
      }, 1000);
    };
    rebootInstance();
  };

  const handleConfigureTimer = () => {
    setTimerOverrideModal(true);
  };

  const handleConfigureDailyReport = () => {
    setConfigureDailyReportModal(true);
  };

  const handleSaveTimerOverride = () => {
    let numberTimerOverride = null;
    if (timerOverride.length > 0) {
      numberTimerOverride = Number.parseInt(timerOverride, 10);
    }
    if (!numberTimerOverride) {
      return;
    }

    if(timerNewLineError || timerOverrideError) {
      return;
    }

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/setTimerOverride`,
      headers: { "Content-Type": "application/json" },
      data: {
        clientApi: query.get("api"),
        clientSecret: query.get("secret"),
        timerOverride: numberTimerOverride,
        timerNewLine
      },
    };
    setTimerUpdatedSuccess(false);
    setLoadingTimerOverride(true);
    axios
      .request(options)
      .then(function (response) {
        setTimerUpdatedSuccess(true);
        setActiveTimerOverride(true);
      })
      .catch(function (error) {
        setOpenMessegeError(true);
      })
      .finally(() => {
        setLoadingTimerOverride(false);
      });
  };

  const checkFunctionalitiesAllowedInPlan = functionality => {
    return props.informations?.user?.plan?.functionalities?.includes(functionality)
  }

  const validateTimerOverride = () => {
    let numberTimerOverride = null;
    if (timerOverride.length > 0) {
      numberTimerOverride = Number.parseInt(timerOverride, 10);
    }
    if(!numberTimerOverride || numberTimerOverride < (props.informations?.user?.plan?.timer ?? 0)) {
      setTimerOverrideError(`Tempo inválido. Tempo mínimo do plano é de ${props.informations?.user?.plan?.timer} segundos.`);
    } else {
      setTimerOverrideError("");
    }
  }

  const validateTimerNewLine = () => {
    if(!timerNewLine || (timerNewLine < 2 || timerNewLine > 60)) {
      setTimerNewLineError("Tempo fracionado inválido. Tempo mínimo de 2 segundos e máximo de 60 segundos.");
    } else {
      setTimerNewLineError("");
    }
  }

  const stylePaper = {
    margin: 10,
    padding: 10,
  };
  return (
    <Paper elevation={6} style={stylePaper}>
      <Modal
        open={timerOverrideModal}
        onClose={() => {
          setTimerUpdatedSuccess(false);
        }}
      >
        <Card sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: "40%",
          boxShadow: 24,
          p: "28px 28px 28px 28px",
        }}>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sx={{mb: 1}}>
              <Alert severity="info">
              <b>DEFINA UM LIMITE PERSONALIZADO DE ENVIOS:</b><br /><br />
              <b>Limite</b> a quantidade de envios por minuto caso queira diminuir os disparos inicialmente.<br />
              <b>Obs:</b> você não poderá diminuir o tempo entre cada envio, somente aumentar.
              </Alert>
            </Grid>
            <Grid item xs={12} pt={4}>
              <FormControlLabel label="DEFINIR TEMPO PERSONALIZADO" control={
                <Checkbox checked={timerOverride != null} onChange={() => {
                  if (timerOverride) {
                    setTimerOverride(null);
                    setTimerNewLine(null);
                  } else {
                    const timer = props.informations?.user?.plan?.timer;
                    setTimerOverride(timer);
                    setTimerNewLine(props.informations?.timerNewLine || timer);
                  }
                }}/>
              } />
            </Grid>
            <Grid item xs={6} pt={4}>
              <TextField
                fullWidth
                label="TEMPO (EM SEGUNDOS)"
                helperText={timerOverrideError || `Tempo mínimo do seu plano atual é de ${props.informations?.user?.plan?.timer}s.`}
                error={!!timerOverrideError}
                value={timerOverride ?? props.informations?.user?.plan?.timer}
                disabled={timerOverride == null}
                onChange={(event) => {
                  let regex = /^[0-9]*$/;
                  if (regex.test(event.target.value)) {
                    setTimerOverride(event.target.value);
                  }
                }}
                onBlur={() => {
                  validateTimerOverride()
                }}
              />
              {
                checkFunctionalitiesAllowedInPlan("NewLine") &&
                <Box mt={4}>
                  <TextField
                    fullWidth
                    type="number"
                    label="TEMPO FRACIONADO (EM SEGUNDOS)"
                    error={!!timerNewLineError}
                    helperText={timerNewLineError || "Esse é o tempo fracionado entre as mensagens do %NewLine%. O Tempo mínimo é 2s, e máximo é de 60s."}
                    value={timerNewLine || props.informations?.timerNewLine || props.informations?.user?.plan?.timer}
                    disabled={timerNewLine == null}
                    onChange={(event) => {
                      const newValue = parseInt(event.target.value);
                      if(newValue < 0 || newValue > 60) {
                        return;
                      }
                      setTimerNewLine(newValue);
                    }}
                    onBlur={() => {
                      validateTimerNewLine()
                    }}
                  />
                </Box>
              }
            </Grid>
            {
              timerUpdatedSuccess &&
              <Grid item xs={12} mt={4}>
                <Alert
                  severity="success"
                  onClose={() => {
                    setTimerUpdatedSuccess(false);
                  }}
                >
                  Tempo de envio atualizado com sucesso.
                </Alert>
              </Grid>
            }
            <Grid item xs={12}>
              <Grid container alignItems={"center"} justifyContent={"right"} spacing={2} sx={{mt: 1}}>
                <Grid item>
                  <Button variant="outlined" onClick={() => {
                    setTimerOverrideModal(false);
                    setTimerUpdatedSuccess(false);
                  }}>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleSaveTimerOverride}
                    disabled={timerOverrideError || timerNewLineError}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Modal>
      <Modal open={configureDailyReportModal}>
        <Card sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: "900px",
          boxShadow: 24,
          p: "28px 28px 28px 28px",
        }}>
          <ChangeDailyReportNumber
            informations={props.informations}
            onCancel={() => {
              setConfigureDailyReportModal(false);
            }}
            onSuccess={() => {
              setConfigureDailyReportModal(false);
              setOpenMessegeSuccess(true);
            }}
          />
        </Card>
      </Modal>
      <Collapse in={openMessegeSuccess}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenMessegeSuccess(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Operação realizada sucesso, atualize a página em 60 segundos!
        </Alert>
      </Collapse>
      <Collapse in={openMessegeError}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenMessegeError(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Não foi possível realizar essa operação.
        </Alert>
      </Collapse>
      <Typography variant="h6" align="center" component="div" gutterBottom>
        Você pode Expirar seu QRCode e Reiniciar sua API:
      </Typography>
      <Stack
        spacing={4}
        justifyContent="center"
        alignItems="center"
        direction="row"
        style={{ paddingTop: 10, PaddingEnd: 5 }}
      >
        <LoadingButton
          loading={loadingDisconnect}
          loadingPosition="start"
          disabled={!activeDisconnect}
          startIcon={<QrCodeScanner />}
          onClick={handleChangeDisconnectInstance}
          variant="outlined"
        >
          Expirar QRCode
        </LoadingButton>
        <LoadingButton
          loading={loadingReboot}
          loadingPosition="start"
          disabled={!activeReboot}
          startIcon={<RestartAlt />}
          onClick={handleChangeRebootInstance}
          variant="outlined"
        >
          Reiniciar API
        </LoadingButton>
        <LoadingButton
          loading={loadingTimerOverride}
          loadingPosition="start"
          disabled={!activeTimerOverride}
          startIcon={<Timer />}
          onClick={handleConfigureTimer}
          variant="outlined"
        >
          Configurar tempo de envio
        </LoadingButton>
        <LoadingButton
          loading={loadingConfigureDailyReport}
          loadingPosition="start"
          disabled={!activeConfigureDailyReport}
          startIcon={<Assessment />}
          onClick={handleConfigureDailyReport}
          variant="outlined"
        >
          Configurar notificação diária
        </LoadingButton>
      </Stack>
    </Paper>
  );
}

export default ManagementInstance;
