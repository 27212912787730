import { Add, Cancel, Check, Clear, Close, Delete, Edit, ExpandLess, PhotoSizeSelectLargeRounded } from "@mui/icons-material";
import { Alert, Box, Button, Card, Checkbox, CircularProgress, Collapse, Divider, Fade, Grid, IconButton, List, ListItem, Modal, Paper, Snackbar, Table, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";

function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

function NewGroupAdder(props) {
  const { name } = props;
  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);
  const [editingName, setEditingName] = useState(name);
  const [errorMessage, setErrorMessage] = useState("");

  let initial = "+";

  const addGroup = (group) => {
    setIsLoadingAdd(true);

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/customer-contact-groups`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
      data: group,
    };
    axios.request(options)
      .then((response) => {
        const createdId = response.data.id;
        const getOptions = {
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL_API}/customer-contact-groups/?_id=${createdId}`,
          headers: {
            "Content-Type": "application/json",
            api: getParam("api"),
            secret: getParam("secret"),
          },
        };
        axios.request(getOptions)
          .then((getResponse) => {
            props.onGroupAdded({
              ...getResponse.data[0]
            });
            setEditingName("Novo grupo");
          })
          .finally(() => {
            setIsAddingGroup(false);
            setIsLoadingAdd(false);
          });
      })
      .catch((e) => {
        setErrorMessage("Não foi possível adicionar o grupo!");
        setShouldShowError(true);
        console.error(e);
        setIsLoadingAdd(false);
      })
  };

  const errorClose = () => {
    setShouldShowError(false);
  };

  return <Box sx={{
    ...props.sx,
    '&:hover': {
      backgroundColor: "#f5f5f5",
    },
  }}>
    <Snackbar
      open={shouldShowError}
      autoHideDuration={6000}
      onClose={errorClose}
    >
      <Alert
        severity="error"
        onClose={errorClose}
      >
        {errorMessage}
      </Alert>
    </Snackbar>
    <Grid
      container
      alignItems="center"
    >
      <Grid item sm="auto">
        <Box
          sx={{
            borderRadius: '50%',
            marginRight: "10px",
          }}
          width="30px"
          height="30px"
          backgroundColor="#1976d2"
        >
          <Typography
            display="block"
            width="30px"
            height="30px"
            textAlign="center"
            lineHeight="30px"
            fontWeight="bold"
            fontSize="15px"
            color="#ffffff"
          >
            {initial}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm>
        <Grid container columnSpacing={1} alignItems="center">
          <Grid item>
            <Collapse in={isAddingGroup} orientation="horizontal">
              <TextField
                  label="Nome"
                  value={editingName}
                  onChange={(event) => {
                    setEditingName(event.target.value);
                  }}
                />
            </Collapse>
          </Grid>
          {isAddingGroup ? (
            <>
              <Grid item>
                {isLoadingAdd ? (
                  <CircularProgress/>
                ) : (
                  <IconButton onClick={() => {
                    addGroup({
                      name: editingName,
                      members: [],
                    });
                  }}><Add/></IconButton>
                )}
              </Grid>
              <Grid item>
                <IconButton onClick={() => {
                  setIsAddingGroup(false);
                }}><Close/></IconButton>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Button onClick={() => {
                setIsAddingGroup(true);
              }}>
                Adicionar Grupo
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  </Box>;
}

function Group(props) {
  const { name } = props.group;
  const [isHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editingName, setEditingName] = useState(name);
  const [isUpdating, setIsUpdating] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let initial = 'X';
  if (name && name.length > 0) {
    initial = name[0];
  }

  const updateGroup = (newName) => {
    if (isUpdating) {
      return;
    }
    setIsUpdating(true);

    const groupUpdate = {
      name: newName,
    };

    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL_API}/customer-contact-groups/${props.group._id}`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
      data: groupUpdate,
    };
    axios.request(options)
      .then((response) => {
        setIsEditing(false);
        props.onGroupUpdate({
          ...props.group,
          ...groupUpdate,
        });
      })
      .catch((e) => {
        setErrorMessage("Não foi possível editar o grupo!");
        setShouldShowError(true);
        console.error(e);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const deleteGroup = () => {
    setIsDeleting(true);
    const options = {
      method: "DELETE",
      url: `${process.env.REACT_APP_BASE_URL_API}/customer-contact-groups/${props.group._id}`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
    };
    axios.request(options)
      .then((response) => {
        setIsEditing(false);
        props.onGroupDelete(props.group);
      })
      .catch((e) => {
        setErrorMessage("Não foi possível apagar o grupo!");
        setShouldShowError(true);
        console.error(e);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const errorClose = () => {
    setShouldShowError(false);
  };

  return <Box sx={{
    ...props.sx,
    '&:hover': {
      backgroundColor: "#f5f5f5",
    },
  }}>
    <Snackbar
      open={shouldShowError}
      autoHideDuration={6000}
      onClose={errorClose}
    >
      <Alert
        severity="error"
        onClose={errorClose}
      >
        {errorMessage}
      </Alert>
    </Snackbar>
    <Grid
      container
      alignItems="center"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Grid item sm="auto">
        <Box
          sx={{
            borderRadius: '50%',
            marginRight: "10px",
          }}
          width="30px"
          height="30px"
          backgroundColor="#1976d2"
        >
          <Typography
            display="block"
            width="30px"
            height="30px"
            textAlign="center"
            lineHeight="30px"
            fontWeight="bold"
            fontSize="15px"
            color="#ffffff"
          >
            {initial}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm>
        { isEditing ? (
          <Grid container columnSpacing={1} alignItems="center">
            <Grid item>
              <TextField
                label="Nome"
                value={editingName}
                onChange={(event) => {
                  setEditingName(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={() => {
                updateGroup(editingName);
              }}>
                <Check/>
              </IconButton>
            </Grid>
            <Grid item>
              { isUpdating ? (
                <CircularProgress/>
              ) : (
                <IconButton onClick={() => {
                  setEditingName(name);
                  setIsEditing(false);
                }}>
                  <Clear/>
                </IconButton>
              )}
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ p: "10px 5px 5px 5px" }}>
            <Typography>{name}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item sm="auto">
        <Fade in={isHovering}>
          { isEditing ? (
            <IconButton onClick={() => {
              if (!isUpdating) {
                setIsEditing(false);
              }
            }}>
              <Cancel/>
            </IconButton>
          ) : (
            <IconButton onClick={() => setIsEditing(true)}>
              <Edit/>
            </IconButton>
          )}
        </Fade>
      </Grid>
      <Grid item sm="auto">
        <Fade in={isHovering && !isEditing} sx={{height: "100%"}}>
          { isDeleting ? (
            <CircularProgress/>
          ) : (
            <IconButton onClick={() => deleteGroup()}>
              <Delete/>
            </IconButton>
          )}
        </Fade>
      </Grid>
      <Grid item sm="auto">
        <Fade in={isHovering || props.selected}>
          <Checkbox checked={props.selected} onChange={(event) => {
            props.onSelectionChanged(props.group, event.target.checked);
          }}
          />
        </Fade>
      </Grid>
    </Grid>
  </Box>;
}


export default function CustomerGroupSelect(props) {
  // console.debug(`Rebuilding with selected ${JSON.stringify(props.selected)}`);
  const { selected } = props;
  // console.debug(`${JSON.stringify(selected)}`);

  const [groups, setGroups] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);

  // console.debug("Rerendering Customer group select");

  const updateGroupList = (isMountedObject) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL_API}/customer-contact-groups`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
    };
    setIsLoadingList(true)
    axios.request(options)
      .then((response) => {
        if (isMountedObject.isMounted) {
          const sortedGroups = response.data.sort((groupA, groupB) => groupA.name.localeCompare(groupB.name));
          setGroups(sortedGroups);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoadingList(false);
      });
  }

  useEffect(() => {
    let isMountedObject = {
      isMounted: true,
    };

    updateGroupList(isMountedObject);

    return () => { isMountedObject.isMounted = false };
  }, []);

  const handleAdd = () => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/customer-contact-groups`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
      data: {
        name: "Novo grupo",
        members: [],
      },
    };
    setIsLoadingAdd(true)
    axios.request(options)
      .then((response) => {
        const newGroupId = response.data.id;
        const getOptions = {
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL_API}/customer-contact-groups/?_id=${newGroupId}`,
          headers: {
            "Content-Type": "application/json",
            api: getParam("api"),
            secret: getParam("secret"),
          },
        };
        const createdGroup = axios.request(getOptions)
          .catch((e) => {
            console.error(e);
            setIsLoadingAdd(false);
          })
          .then((response) => {
            if (response.data.length && response.data.length > 0) {
              setGroups([response.data[0], ...groups]);
            }
            setIsLoadingAdd(false);
          });
      })
      .catch((e) => {
        console.error(e);
        setIsLoadingAdd(false);
      })
  };

  return (
    <Box>
      <Grid container alignItems="baseline">
        <Grid item sm={12}>
          <Typography
            sx={{
              ml: "16px",
              mt: "16px",
              mb: "16px",
            }}
          >Selecionado: {selected ? selected.name : "Nenhum"}</Typography>
        </Grid>
      </Grid>
      <Divider/>
      {isLoadingList ? (
        <Grid container>
          <Grid item sm align="center">
            <CircularProgress sx={{mt: "24px", mb: "24px"}}/>
          </Grid>
        </Grid>
      ) : (
        <List sx={{maxHeight: "240px", overflow: "auto", width: "100%"}}>
          <ListItem key={0}>
            <NewGroupAdder
              name="Novo grupo"
              sx={{width: "100%"}}
              onGroupAdded={(group) => {
                setGroups([group, ...groups]);
              }}
            />
          </ListItem>
          { groups.map((group, i) => {
            return (
              <ListItem key={i + 1}>
                <Group
                  group={group}
                  sx={{width: "100%"}}
                  selected={selected && selected._id == group._id}
                  onSelectionChanged={(group, checked) => {
                    // console.debug(`AAAA ${checked}`);
                    if (checked) {
                      props.onGroupSelection(group);
                    } else {
                      props.onGroupSelection(null);
                    }
                  }}
                  onGroupUpdate={(newGroup) => {
                    console.debug(`Group update ${JSON.stringify(newGroup)}`);
                    setGroups(groups.map((group) => {
                      if (group._id == newGroup._id) {
                        return newGroup;
                      } else {
                        return group;
                      }
                    }));
                  }}
                  onGroupDelete={(deleted) => {
                    setGroups(groups.filter((group) => group._id != deleted._id));
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  )
}