import { Add, AttachFile, Cancel, Check, Clear, Delete, Edit, FileDownload, Groups } from "@mui/icons-material";
import { Alert, Box, Button, Card, Checkbox, CircularProgress, Fade, Grid, IconButton, InputAdornment, List, ListItem, MenuItem, Modal, Snackbar, TextField, Typography } from "@mui/material"
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import CustomerGroupSelect from "../CustomerGroupSelect";
import { PhoneNumberInput as PhoneNumber } from "../PhoneNumberInput";

function getParam(param) {
  return new URLSearchParams(window.location.search).get(param);
}

function Contact(props) {
  const { name, phone, groups } = props.contact;

  const getGroup = () => {
    if (groups && groups.length > 0) {
      return groups[0];
    } else {
      return undefined;
    }
  };

  const [isHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [editingName, setEditingName] = useState(name);
  const [editingPhone, setEditingPhone] = useState(phone);
  const [selectingGroupSelection, setSelectingGroupSelection] = useState(getGroup());
  const [editingGroupSelection, setEditingGroupSelection] = useState(getGroup());

  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditingGroup, setIsEditingGroup] = useState(false);

  const [shouldShowError, setShouldShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let initial = 'X';
  if (name && name.length > 0) {
    initial = name[0];
  }

  // console.debug("Rerendering Contact");

  const updateContact = (newName, newPhone, newGroup) => {
    if (isUpdating) {
      return;
    }
    setIsUpdating(true);

    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL_API}/customer-contacts/${props.contact._id}`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
      data: {
        name: newName,
        phone:  newPhone,
        groups: newGroup ? [newGroup] : undefined,
      },
    };
    axios.request(options)
      .then((response) => {
        setIsEditing(false);
        props.contactUpdated();
      })
      .catch((e) => {
        setErrorMessage("Não foi possível editar o contato!");
        setShouldShowError(true);
        console.error(e);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const errorClose = () => {
    setShouldShowError(false);
  };

  return <Box sx={{
    width: "100%",
    p: "5px",
    m: "5px",
    '&:hover': {
      backgroundColor: "#f5f5f5",
    },
  }}>
    <Modal open={isEditingGroup}>
      <Card sx={{
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        width: "900px",
        boxShadow: 24,
        p: "4px 4px 4px 4px",
      }}>
        <CustomerGroupSelect selected={selectingGroupSelection} onGroupSelection={(group) => {
          setSelectingGroupSelection(group);
        }}/>
        <Grid container justifyContent="right" columnSpacing={2}>
          <Grid item>
            <Button variant="text" onClick={() => {
              setSelectingGroupSelection(editingGroupSelection);
              setIsEditingGroup(false)
            }}>Cancelar</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => {
              setEditingGroupSelection(selectingGroupSelection);
              setIsEditingGroup(false);
            }}>Salvar</Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
    <Snackbar
      open={shouldShowError}
      autoHideDuration={6000}
      onClose={errorClose}
    >
      <Alert
        severity="error"
        onClose={errorClose}
      >
        {errorMessage}
      </Alert>
    </Snackbar>
    <Grid
      container
      alignItems="center"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Grid item sm="auto">
        <Box
          sx={{
            borderRadius: '50%',
            marginRight: "10px",
          }}
          width="60px"
          height="60px"
          backgroundColor="#1976d2"
        >
          <Typography
            display="block"
            width="60px"
            height="60px"
            textAlign="center"
            lineHeight="60px"
            fontWeight="bold"
            fontSize="30px"
            color="#ffffff"
          >
            {initial}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm>
        { isEditing ? (
          <Grid container columnSpacing={1} alignItems="center">
            <Grid item>
              <TextField
                label="Nome"
                value={editingName}
                onChange={(event) => {
                  setEditingName(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <PhoneNumber
                label="Telefone"
                value={editingPhone}
                onChange={(value) => {
                  setEditingPhone(value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Grupo"
                disabled={editingGroupSelection == undefined}
                onClick={(e) => {
                  e.preventDefault();
                }}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment>
                      <Button onClick={() => setIsEditingGroup(true)}><Groups/></Button>
                    </InputAdornment>
                  ),
                }}
                value={editingGroupSelection ? editingGroupSelection.name : "Nenhum"}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={() => {
                updateContact(editingName, editingPhone, editingGroupSelection);
              }}>
                <Check/>
              </IconButton>
            </Grid>
            <Grid item>
              { isUpdating ? (
                <CircularProgress/>
              ) : (
                <IconButton onClick={() => {
                  setEditingName(name);
                  setEditingPhone(phone);
                  setIsEditing(false);
                }}>
                  <Clear/>
                </IconButton>
              )}
            </Grid>
          </Grid>
        ) : (
          <Box item sx={{ p: "10px 5px 5px 5px" }}>
            <Typography>{name}</Typography>
            <Typography fontSize="15px" color="#707070">{phone}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item sm="auto">
        <Grid container alignItems="center">
          <Grid item>
            <Typography color="#707070" sx={{mr: "32px"}}>{(()=>{
              const group = getGroup();
              if (group) {
                return group.name;
              } else {
                return "";
              }
            })()}</Typography>
          </Grid>
          <Grid item>
            <Fade orientation="horizontal" in={isHovering}>
              { isEditing ? (
                <IconButton onClick={() => {
                  if (!isUpdating) {
                    setIsEditing(false);
                  }
                }}>
                  <Cancel/>
                </IconButton>
              ) : (
                <IconButton onClick={() => setIsEditing(true)}>
                  <Edit/>
                </IconButton>
              )}
            </Fade>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm="auto">
        <Checkbox checked={props.selected} onChange={(event) => {
          props.onSelectionChanged(props.contact, event.target.checked);
        }}
        />
      </Grid>
    </Grid>
  </Box>;
}

function ContactAdderTextFields(props) {
  const [nameOfContactBeingAdded, setNameOfContactBeingAdded] = useState("");
  const [phoneOfContactBeingAdded, setPhoneOfContactBeingAdded] = useState("");

  const handleNameChange = (e) => {
    setNameOfContactBeingAdded(e.target.value);
    if (props.onNameChange) {
      props.onNameChange(e.target.value);
    }
  };
  const handlePhoneChange = (value) => {
    setPhoneOfContactBeingAdded(value)
    if (props.onPhoneChange) {
      props.onPhoneChange(value);
    }
  };

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="right">
      <Grid item sm={6}>
        <TextField
          variant="outlined"
          label="Nome"
          fullWidth
          onChange={handleNameChange}
          value={nameOfContactBeingAdded}
        />
      </Grid>
      <Grid item sm={6}>
        <PhoneNumber
          variant="outlined"
          label="Celular"
          fullWidth
          onChange={handlePhoneChange}
          value={phoneOfContactBeingAdded}
        />
      </Grid>
    </Grid>
  )
}

function ContactAdder(props) {
  const nameOfContactBeingAdded = useRef("");
  const phoneOfContactBeingAdded = useRef("");
  const [groupOfContactBeingAdded, setGroupOfContactBeingAdded] = useState(undefined);
  const [importingFromFile, setImportingFromFile] = useState(false);
  const fileInput = useRef();
  const fileData = useRef("");
  const [filename, setFilename] = useState("");
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [selectedSeparator, setSelectedSeparator] = useState(",");

  const readFile = (file) => {
    const reader = new FileReader();
    setFilename(file.name);
    reader.onload = (loadEvent) => {
      console.debug(`Loaded`);
      fileData.current = loadEvent.target.result;
      setIsFileLoaded(true);
    };
    reader.readAsText(file);
  };

  const separators = [
    {
      name: "Vírgula (,)",
      value: ",",
    },
    {
      name: "Ponto e vírgula (;)",
      value: ";",
    },
    {
      name: "Tabulação",
      value: "\t",
    },
  ];

  return (
    importingFromFile ? (<>
      <input
        ref={fileInput}
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            readFile(e.target.files[0]);
          }
        }}
        hidden
        type="file"
        id="contact-list-import-file-add"
      />
      <Grid container alignItems={"center"} spacing={2} justifyContent={"center"}>
        <Grid item xs={12}>
          <Alert severity="info">
            Adicione um arquivo CSV com os contatos que devem ser adicionados.
            A primeira coluna do arquivo deve ser o "name" (o nome), e a segunda "phone", (o telefone).
            Lembre-se de incluir o DDI.
          </Alert>
        </Grid>
        <Grid item xs={6}>
          <Grid container alignItems="center" spacing={2} justifyContent="center">
            <Grid item>
              <Button variant="outlined" onClick={() => fileInput.current.click()}>Adicionar arquivo</Button>
            </Grid>
            {isFileLoaded && <Grid item>
              <Typography>Carregado: {filename}</Typography>
            </Grid>}
          </Grid>
        </Grid>
        <Grid item xs={6} align="center">
          <TextField select label="Separador do CSV" fullWidth value={selectedSeparator} onChange={e => setSelectedSeparator(e.target.value)}>
            {separators.map(sep => {
              return (
                <MenuItem key={sep.value} value={sep.value}>
                  {sep.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} alignContent="center" alignItems="center" align="center">
          <Typography>Seleção de grupo:</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomerGroupSelect selected={groupOfContactBeingAdded} onGroupSelection={(group) => {
            // console.debug(`${JSON.stringify(group)}, ${JSON.stringify(groupOfContactBeingAdded)}`);
            setGroupOfContactBeingAdded(group);
          }}/>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="right">
        <Grid item>
          <Button variant="text" onClick={() => setImportingFromFile(false)}>Voltar</Button>
        </Grid>
        <Grid item>
          <Button variant="text" onClick={() => props.onCancel()}>Cancelar</Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            disabled={!isFileLoaded}
            onClick={() => {
              if (props.onAddBulkContacts) {
                props.onAddBulkContacts(fileData.current, selectedSeparator, groupOfContactBeingAdded);
              } else {
                console.warn("Missing onAddBulkContacts prop");
              }
            }}
          >
            Adicionar contatos
          </Button>
        </Grid>
      </Grid>
    </>) : (<>
      <Grid container>
        <Grid item align="right" xs={12} sx={{marginBottom: 1}}>
          <Button variant="text" onClick={() => setImportingFromFile(true)}>
            <AttachFile/>Importar de arquivo
          </Button>
        </Grid>
      </Grid>
      <ContactAdderTextFields
        onNameChange={(newName) => {
          nameOfContactBeingAdded.current = newName;
        }}
        onPhoneChange={(newName) => {
          phoneOfContactBeingAdded.current = newName;
        }}
      />
      <Grid container spacing={2} alignItems="center" justifyContent="right">
        <Grid item sm={12}>
          <Card>
            <CustomerGroupSelect selected={groupOfContactBeingAdded} onGroupSelection={(group) => {
              // console.debug(`${JSON.stringify(group)}, ${JSON.stringify(groupOfContactBeingAdded)}`);
              setGroupOfContactBeingAdded(group);
            }}/>
          </Card>
        </Grid>
        <Grid item>
          <Button variant="text" onClick={() => props.onCancel()}>Cancelar</Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => {
              props.onAddContact(nameOfContactBeingAdded.current, phoneOfContactBeingAdded.current, groupOfContactBeingAdded);
            }}
          >
            Adicionar contato
          </Button>
        </Grid>
      </Grid>
    </>)
  );
}

export default function CustomerContactList() {
  const [contacts, setContacts] = useState([]);
  const [isAddingContact, setIsAddingContact] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);

  const [selectedContacts, setSelectedContacts] = useState([]);

  const updateContactList = (isMountedObject) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL_API}/customer-contacts`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
    };
    setIsLoadingList(true)
    axios.request(options)
      .then((response) => {
        // console.debug(JSON.stringify(response.data));
        if (isMountedObject.isMounted) {
          const sortedContacts = response.data.sort((contactA, contactB) => contactA.name.localeCompare(contactB.name));
          // console.debug(`Sorted contacts: ${JSON.stringify(sortedContacts)}`);
          setContacts(sortedContacts);
          setIsLoadingList(false);
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    let isMountedObject = {
      isMounted: true,
    };

    updateContactList(isMountedObject);

    return () => { isMountedObject.isMounted = false };
  }, []);

  const addContact = (nameOfContactBeingAdded, phoneOfContactBeingAdded, groupOfContactBeingAdded) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/customer-contacts`,
      headers: {
        "Content-Type": "application/json",
        api: getParam("api"),
        secret: getParam("secret"),
      },
      data: {
        name: nameOfContactBeingAdded,
        phone: phoneOfContactBeingAdded,
        groups: groupOfContactBeingAdded ? [groupOfContactBeingAdded._id] : undefined,
        isActive: true,
      },
    };
    setIsLoadingList(true);
    axios.request(options)
      .then((response) => {
        console.debug(JSON.stringify(response.data));
        updateContactList({ isMounted: true });
        setIsAddingContact(false);
      })
      .catch((error) => {
        setIsLoadingList(false);
        console.error(error);
      });
  };
  const addBulkContacts = (contactsCsv, separator, group) => {
    console.debug(`${contactsCsv}, ${separator}`);
    const groupParam = `?separator=${separator}` + (group ? `&bulkGroupId=${group._id}` : "");
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/customer-contacts${groupParam}`,
      headers: {
        "Content-Type": "text/csv",
        api: getParam("api"),
        secret: getParam("secret"),
      },
      data: contactsCsv,
    };
    setIsLoadingList(true);
    axios.request(options)
      .then((response) => {
        console.debug(JSON.stringify(response.data));
        updateContactList({ isMounted: true });
        setIsAddingContact(false);
      })
      .catch((error) => {
        setIsLoadingList(false);
        console.error(error);
      });
  };
  const contactSelectionChanged = (contact, isSelected) => {
    if (isSelected) {
      setSelectedContacts([...selectedContacts, contact]);
    } else {
      const newList = [...selectedContacts];
      const contactIdx = newList.findIndex((el) => el.phone === contact.phone);
      if (contactIdx >= 0) {
        newList.splice(contactIdx, 1);
        setSelectedContacts(newList);
      } else {
        console.error("Could not find contact on list when deselecting");
      }
    }
  };
  const handleDelete = () => {
    setIsLoadingList(true);
    const numResolutions = {
      count: 0,
    };
    for (let i = 0; i < selectedContacts.length; i++) {
      const someContact = selectedContacts[i];

      console.debug(`${JSON.stringify(someContact)}`);
      const options = {
        method: "DELETE",
        url: `${process.env.REACT_APP_BASE_URL_API}/customer-contacts/${someContact._id}`,
        headers: {
          "Content-Type": "application/json",
          api: getParam("api"),
          secret: getParam("secret"),
        },
      };
      axios.request(options)
        .catch((error) => {
          setIsLoadingList(false);
          console.error(error);
        })
        .finally(() => {
          numResolutions.count++;
          if (numResolutions.count >= selectedContacts.length) {
            setIsLoadingList(false);
            updateContactList({ isMounted: true });
            selectedContacts.length = 0;
          }
        });
    }
  }

  const exportarListaDeContatos = () => {
    const csvBlob = new Blob([
      contacts.map(contact => `${contact.name},"=""${contact.phone}"""`)
      .reduce((acc, current) => `${acc}\n${current}`, 'SEP=,\nname,phone')
    ], { type: "text/csv" });

    const downloadLink = document.createElement('a');

    downloadLink.download = 'lista_de_contatos.csv';
    downloadLink.href = window.URL.createObjectURL(csvBlob);
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(downloadLink.href);
  };

  return (
    <Grid container direction="column" sx={{
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: "28px 28px 28px 28px",
    }}>
      <Grid item>
        <Grid container alingItems="center">
          <Grid item sm={4} align="center">
            <Button onClick={() => setIsAddingContact(true)}>
              <><Add />Adicionar</>
            </Button>
          </Grid>
          <Grid item sm={4} align="center">
            <Button onClick={() => exportarListaDeContatos()}>
              <><FileDownload/>Exportar</>
            </Button>
          </Grid>
          <Grid item sm={4} align="center">
            <Button disabled={selectedContacts.length === 0} onClick={handleDelete}><Delete />Remover</Button>
          </Grid>
        </Grid>
        <Modal open={isAddingContact}>
          <Card sx={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            width: "900px",
            boxShadow: 24,
            p: "28px 28px 28px 28px",
          }}>
            <ContactAdder
              onAddContact={addContact}
              onAddBulkContacts={addBulkContacts}
              onCancel={() => setIsAddingContact(false)}
            />
          </Card>
        </Modal>
        {isLoadingList && (
          <Grid container justifyContent={"center"}>
            <Grid item>
              <CircularProgress
                size={68}
                sx={{
                  margin: "100px 100px 100px 100px",
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item sm>
        <List sx={{height: "100%"}}>
          {!isLoadingList && contacts.map((contact, i) => {
            let selected = false;
            if (selectedContacts.findIndex((el) => el._id === contact._id) >= 0) {
              selected = true;
            }
            return (
              <ListItem key={i}>
                <Contact
                  key={i}
                  contact={contact}
                  selected={selected}
                  onSelectionChanged={contactSelectionChanged}
                  contactUpdated={() => {
                    updateContactList({ isMounted: true });
                  }}
                />
              </ListItem>
            )
          })}
        </List>
      </Grid>
    </Grid>
  );
}