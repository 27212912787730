import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Paper, Box, CircularProgress, Alert } from "@mui/material";

import ChangeMultidevices from "../../components/ChangeMultidevices";

function GeneratingQrCode() {
  return <QueryParams />;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function QueryParams() {
  const [timeInterval, setTimeInterval] = useState(0);
  const [qrCode, setQrCode] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");

  let query = useQuery();

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 3000);

  const optionDate = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  useEffect(() => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL_API}/instances/microservices/generateQRCode`,
      headers: { "Content-Type": "application/json" },
      data: { clientApi: query.get("api"), clientSecret: query.get("secret") },
    };

    axios
      .request(options)
      .then(function (response) {
        setQrCode(response.data.base64Qr);
        setLastUpdated(
          new Date(response.data.updated_at).toLocaleDateString(
            "pt-br",
            optionDate
          )
        );
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [timeInterval]);

  const stylePaper = {
    margin: 10,
    padding: 10,
  };

  return (
    <Paper elevation={6} style={stylePaper}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
          p: 2,
          m: 2,
          bgcolor: "background.paper",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <label style={{ color: "#707070", fontSize: 16 }}>
            ✅ QRCode atualizado em: <strong style={{ color: "green" }}>{lastUpdated}</strong>.
          </label>
          <img style={{ padding: 20 }} width={300} height={300} src={qrCode} />
          <CircularProgress />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <ChangeMultidevices />
        </Box>
      </Box>
    </Paper>
  );
}

export default GeneratingQrCode;
