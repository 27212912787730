import React, { useEffect, useState } from "react";

import ChipsArray from "../../components/Inputs/InputNumbers";
import Paper from "@mui/material/Paper";
import { seo } from "../../util/seo";
import { Alert, AlertTitle } from "@mui/material";

import "./index.css";
import axios from "axios";

export default function Home() {
  const [aviso, setAviso] = useState([]);

  const stylePaper = {
    margin: 10,
    padding: 20,
  };
  useEffect(async () => {
    seo({
      title: "Mensagem",
      metaDescription: "With some meta description",
    });
    getAviso();
  }, []);

  const getAviso = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL_API}/notifications`)
      .then((res) => {
        console.log(res.data);
        setAviso(res.data);
      });
  };
  return (
    <>
      {aviso.map(
        (item) =>
          item.isActive && (
            <Alert severity={item.type} style={{ marginTop: 15 }}>
              <AlertTitle>{item.title}</AlertTitle>
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </Alert>
          )
      )}
      <Paper elevation={6} style={stylePaper}>
        <ChipsArray />
      </Paper>
    </>
  );
}
