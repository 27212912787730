import React from "react";
import { Chart } from "react-google-charts";

export const options = {
  isStacked: true,
  height: 300,
  legend: { position: "top", maxLines: 3 },
  vAxis: { minValue: 0 },
  colors: ["red", "green"],
};

export function ChartLine({ data }) {
  return (
    <Chart
      chartType="AreaChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
}
