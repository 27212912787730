import * as React from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import IconButton from "@mui/material/IconButton";

import { blue, green, grey } from "@mui/material/colors";

export default function RangeDate(props) {
  return (
    <Grid
      item
      xs={12}
      direction={"row"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack direction={"row"} spacing={2}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => props.setDom(!props.dom)}
        >
          <Avatar sx={{ bgcolor: props.dom ? blue[500] : grey[500] }}>D</Avatar>
        </IconButton>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => props.setSeg(!props.seg)}
        >
          <Avatar sx={{ bgcolor: props.seg ? blue[500] : grey[500] }}>S</Avatar>
        </IconButton>

        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => props.setTer(!props.ter)}
        >
          <Avatar sx={{ bgcolor: props.ter ? blue[500] : grey[500] }}>T</Avatar>
        </IconButton>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => props.setQua(!props.qua)}
        >
          <Avatar sx={{ bgcolor: props.qua ? blue[500] : grey[500] }}>Q</Avatar>
        </IconButton>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => props.setQui(!props.qui)}
        >
          <Avatar sx={{ bgcolor: props.qui ? blue[500] : grey[500] }}>Q</Avatar>
        </IconButton>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => props.setSex(!props.sex)}
        >
          <Avatar sx={{ bgcolor: props.sex ? blue[500] : grey[500] }}>S</Avatar>
        </IconButton>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => props.setSab(!props.sab)}
        >
          <Avatar sx={{ bgcolor: props.sab ? blue[500] : grey[500] }}>S</Avatar>
        </IconButton>
      </Stack>
    </Grid>
  );
}
