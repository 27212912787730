import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { codes } from "../../constants/countrycodes";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/styles";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import Emoji from "react-emoji-render";

import { VcfToJson } from "../../util/";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles({
  buttonContainer: { height: 55 },
  fild: { width: "100%" },
  "@media (max-width: 425px)": {
    buttonContainer: {
      width: "100%",
    },
  },
  "@media (max-width: 768px)": {
    buttonContainer: {
      width: "100%",
    },
  },
  "@media (min-width: 600px)": {
    buttonContainer: {
      marginLeft: 10,
    },
  },
});
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      border: "none",
    },
  },
};

export default function Contacs({ numbers }) {
  const classes = useStyles();

  const [codeCountry, setCodeCountry] = React.useState("+55🇧🇷");

  const [msgAlert, setMsgAlert] = useState("");
  const [allNumbers, setAllNumbers] = React.useState(null);
  const [allNumbersArray, setAllNumbersArray] = React.useState([]);
  const [alertErroState, setAlertErroState] = useState(false);

  const handleDelete = (index) => {
    let tmp = allNumbersArray.filter((el, k) => k !== index);
    setAllNumbersArray(tmp);
    numbers(tmp);
  };

  const handleChange = (event) => {
    if (allNumbers === "") {
      setMsgAlert("O campo telefone, não pode ser vazio!");
      setAlertErroState(true);
      return;
    } else {
      setAlertErroState(false);
    }

    const {
      target: { value },
    } = event;
    console.log(value);
    setCodeCountry(value);
  };
  const FilterNumbers = () => {
    if (allNumbers !== null) {
      let tmp = allNumbers.split(",");
      tmp = tmp.filter((elem) => {
        return elem !== "";
      });
      tmp = tmp.map((elem) => {
        let t = elem.replace(/[^0-9+]/g, "");
        let code = codeCountry.replace(/[^0-9+]/g, "");
        return { number: code + t, name: "" };
      });
      setAllNumbersArray(tmp);
      numbers(tmp);
      setAllNumbers([]);
    }
  };
  const onChange = (e) => {
    var input = e.target;

    var reader = new FileReader();
    reader.onload = function () {
      var text = reader.result;
      let json = VcfToJson(text);
      let tam = json.length;
      let result = [];
      for (var i = 0; i < tam; i++) {
        if (json[i].TEL) {
          let num = json[i].TEL.value.replace(/[^0-9+]/g, "");
          result.push({
            name: json[i].FN ? json[i].FN : "",
            number: num,
          });
        }
      }
      setAllNumbersArray(result);
      numbers(result);
      setAllNumbers([]);
    };
    reader.readAsText(input.files[0]);
  };
  return (
    <>
      <Typography display="block" style={{ color: "#707070" }}>
        Insira os números que deseja enviar a Mensagem.
      </Typography>
      <Grid container>
        <Grid item xs={5} sm={2} md={2} lg={0} xl={2}>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            value={codeCountry}
            onChange={handleChange}
            style={{ marginRight: 10, marginBottom: 10 }}
            MenuProps={MenuProps}
          >
            {codes.map((option) => (
              <MenuItem
                key={option.name}
                value={option.dial_code + option.flag}
              >
                {option.dial_code} <Emoji text={option.flag} />
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={7} sm={4} md={4} lg={0} xl={0}>
          <TextField
            className={classes.fild}
            //type="number"
            placeholder="Ex: (00)00100-0000,(00)00100-0000"
            value={allNumbers}
            onChange={(value) => setAllNumbers(value.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={0} xl={0}>
          <Button
            className={classes.buttonContainer}
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => FilterNumbers()}
          >
            Adicionar
          </Button>
          <input
            accept="image/*"
            className={classes.input}
            style={{ display: "none" }}
            id="raised-button-file"
            onChange={onChange}
            multiple
            type="file"
          />
          {/* <label htmlFor="raised-button-file">
            <Button
              className={classes.buttonContainer}
              variant="outlined"
              component="span"
              startIcon={<ContactPageIcon />}
            >
              Importar Contatos
            </Button>
          </label> */}
        </Grid>
      </Grid>
      <Typography style={{ color: "#707070" }} display="block">
        Lista de números:
      </Typography>
      <div
        style={{
          border: "solid 0.5px #ddd",
          padding: 15,
          overflowY: "scroll",
          height: 70,
        }}
      >
        {allNumbersArray.map((item, k) => (
          <Chip
            label={item.number}
            onDelete={() => handleDelete(k)}
            deleteIcon={<DeleteIcon />}
            variant="outlined"
          />
        ))}
        {allNumbersArray.length === 0 ? (
          <Typography style={{ color: "#707070" }} display="block">
            Nenhum número inserido!
          </Typography>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
